import { TSearchFilters } from "src/types";
import { TUsersListingApiResponse, TUsersListingFiltersApiRequest } from "src/types/TUsersListing";

import { TOutOfBuisnessReason } from "./PublicServices";
import client from "./client";

class usersListingServiceClass {
	postUsersListingSearch(
		params: TSearchFilters & { query: TUsersListingFiltersApiRequest }
	): Promise<AxiosResponse<TUsersListingApiResponse>> {
		return client.post("/api-client-v2/users/search", params);
	}

	updateVipStatus(params: { usersIds: number[]; isVip: boolean }) {
		return client.put(`/api-client-v2/users/vip`, params, {
			timeout: 0,
		});
	}

	getOutOfBuisnessReasons(): Promise<TOutOfBuisnessReason[]> {
		return client.get("/api-client-v2/out-of-business-reasons").then((resp) => resp.data as TOutOfBuisnessReason[]);
	}

	updateOutOfBuisnessReason(usersIds: number[], outOfBusinessReasonId: string) {
		return client.put(
			`/api-client-v2/users/out-of-business`,
			{
				usersIds,
				outOfBusinessReasonId,
			},
			{
				timeout: 0,
			}
		);
	}

	deleteUser(userId: number) {
		return client.delete(`/api-client-v2/users/${userId}`, {
			timeout: 0, // Sur cette requete timeout infini; requete longue (purge)
		});
	}
}
const usersListingService = new usersListingServiceClass();
export default usersListingService;
