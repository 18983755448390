import { FC } from "react";

import { t } from "i18next";
import { useQuery } from "react-query";

import { Checkbox, RadioButton, InfoMessage } from "@zolteam/react-ras-library";
import { Spinner } from "src/components/atoms";
import { FilterCardHeader } from "src/components/molecules";

import cn from "src/utils/cn";

import style from "./CheckboxListFilter.module.css";

type TCheckboxItems = Record<string, boolean>;

interface ICheckboxListFilterProps {
	items: TCheckboxItems;
	onChange: (type: string) => void;
	indentedFirstLevel?: boolean;
	labelClassName?: string;
	selectedLabelClassName?: string;
	handleReset?: () => void;
	title?: string;
	getId?: (index: string) => string;
	getLabel?: (type: string, items: TCheckboxItems) => string;
	getValue?: (type: string) => string | boolean;
	listStyle?: React.CSSProperties;
	orientation?: "horizontal" | "vertical";
	type?: "checkbox" | "radio";
	name?: string;
	isLoading?: boolean;
	error?: string;
}

export const CheckboxListFilter: FC<ICheckboxListFilterProps> = ({
	items,
	onChange,
	indentedFirstLevel = false,
	labelClassName = "",
	selectedLabelClassName = "",
	handleReset = null,
	title = "",
	getId = (index: string) => `checkbox-${index}`,
	getLabel = (type: string) => type,
	getValue = (type) => type,
	listStyle = {},
	orientation = "vertical",
	type = "checkbox",
	name = "",
	isLoading = false,
	error = "",
}) => (
	<div>
		{!!title && <FilterCardHeader title={title} handleReset={!error ? handleReset : undefined} />}
		{isLoading ? (
			<Spinner />
		) : error ? (
			<div className="[&>div]:w-full [&>div]:box-border">
				<InfoMessage withIcon color="error">
					{error}
				</InfoMessage>
			</div>
		) : (
			<div
				style={{
					display: "flex",
					gap: "0.4rem",
					...(orientation === "horizontal"
						? {
								justifyContent: "space-between",
								flexWrap: "wrap",
							}
						: {
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}),
					...listStyle,
				}}
			>
				{Object.keys(items)?.map((item, index) => {
					const elemId = getId([name, index.toString()].join("-"));
					return (
						<label
							key={item}
							style={{
								marginLeft: indentedFirstLevel && index !== 0 ? "8px" : "0px",
							}}
							className={cn([
								"row gap-1 items-center",
								labelClassName,
								!!getValue(item) && selectedLabelClassName,
							])}
							htmlFor={elemId}
						>
							{type === "radio" ? (
								<RadioButton
									type={type}
									className={style.checkbox}
									id={elemId}
									checked={getValue(item)}
									onChange={() => onChange(item)}
									color="primary"
									name={elemId}
								/>
							) : (
								<Checkbox
									type={type}
									className={style.checkbox}
									id={elemId}
									value={getValue(item)}
									onChange={() => onChange(item)}
									theme="primary"
								/>
							)}
							<span>{getLabel(item, items)}</span>
						</label>
					);
				})}
			</div>
		)}
	</div>
);

interface IAsyncCheckboxListFilterProps extends Omit<ICheckboxListFilterProps, "items"> {
	handleFetch: () => Promise<unknown>;
}

export const AsyncCheckboxListFilter: FC<IAsyncCheckboxListFilterProps> = ({ handleFetch, ...props }) => {
	const { isLoading, data, error } = useQuery(["asyncCheckboxListFilter", props.name], handleFetch);

	return (
		<CheckboxListFilter
			{...props}
			items={data as unknown as TCheckboxItems}
			isLoading={isLoading}
			error={!!error && t("global.error")}
		/>
	);
};
