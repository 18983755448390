// @ts-nocheck
import React, { useCallback, useEffect, useRef, useMemo, useState } from "react";

import { useTranslation, Trans } from "react-i18next";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useLocation } from "react-router-dom";

// services
import {
	postMatchingOrderSearch,
	getAgencies,
	postSelectProfile,
	postDeslectProfile,
	postAddMatchedProfiles,
} from "../../../services/commandService";
import { postCreateUser } from "src/services/commands/postCreateUserService";

import useFilterState from "../../../hooks/useFilterState";
import useOrderData from "../../../hooks/useOrderData";
// store
import { useAppContext } from "../../../store/AppContext";
import { useMatchingContext } from "../../../store/MatchingContext";
import { useToastContext } from "../../../store/ToastContext";

// utils
import { MATCHING_ERRORS, PROFILES_STATUS } from "../../../constants/index";
import { Picto } from "@zolteam/react-ras-library";

import toggleModal from "src/utils/toggleModal";

// style
import style from "./MatchingResultList.module.css";

const useMatchingResultList = () => {
	const { orderId } = useParams();
	const { pathname } = useLocation();
	const [, dispatchApp] = useAppContext();
	const [, toastDispatch] = useToastContext();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [modalStates, setModalStates] = useState({
		addAllProfiles: false,
		createUser: false,
		createUserInterviewData: false,
		blockCreateUser: false,
	});
	const [selectableProfilesNumber, setSelectableProfilesNumber] = useState(null);
	const [clickedProfile, setClickedProfile] = useState(null);

	const [{ filters, emptySection, unifiedTempWorkerId, orderAgencyId, hasFilters }, dispatchMatching] =
		useMatchingContext();
	// TODO remove this and get this data from context
	const { agencyData, hasCommonQualificationId, commonQualificationDetails, isLoading, orderData } =
		useOrderData(orderId);
	const { getInitialFilters, isEmptyQualification } = useFilterState(orderId);
	const initialFilters = useMemo(() => getInitialFilters("query"), []);

	useEffect(() => {
		if (hasCommonQualificationId === undefined || hasCommonQualificationId === false) return;
		dispatchMatching({
			type: "SET_WIDE_LOADING",
			payload: {
				isDisplayed: true,
				message: "Chargement en cours...",
			},
		});
	}, [pathname, hasCommonQualificationId]);

	useEffect(() => {
		if (initialFilters.agency) {
			dispatchMatching({
				type: "SET_FILTERS",
				payload: { ...filters, agency: initialFilters.agency },
			});
		}
	}, [initialFilters.agency]);

	const { data: agencies } = useQuery("getAgencies", () => getAgencies(""), {
		select: (data) => data?.data,
	});

	const commonQualification = useMemo(() => {
		if (
			initialFilters?.qualifications?.length > 0 &&
			initialFilters?.qualifications?.find((q) => q === commonQualificationDetails?.id) === undefined
		) {
			// At least one qualification is in the params but not is not the order qualification
			return [];
		}
		return commonQualificationDetails?.id ? [commonQualificationDetails?.id] || [] : [];
	}, [commonQualificationDetails]);

	const commonDocumentsIds = useMemo(() => filters.commonDocuments.map((item) => item.id), [filters.commonDocuments]);

	const initialAgencyFetched = useRef(false);

	useEffect(() => {
		const fetchInitialData = async () => {
			const foundAgency = agencies?.find((agency) => agency.id === agencyData?.id);

			if (orderData?.data?.agencyId) {
				dispatchMatching({
					type: "SET_ORDER_AGENCY_ID",
					payload: orderData?.data?.agencyId,
				});
			}

			const mergedQualifications = [...(filters?.qualifications || []), ...(commonQualification || [])];
			const uniqueQualifications = [...new Set(mergedQualifications)];

			const newFilters = {
				...filters,
				qualifications: isEmptyQualification ? [] : uniqueQualifications,
				agency: initialFilters.agency || foundAgency?.tempoFolderName || null,
				orderAgencyId: foundAgency?.id || null,
			};

			if (orderData && !filters?.period?.startDate && !filters?.period?.endDate) {
				newFilters.period = {
					startDate: orderData?.data.period?.startDate,
					endDate: orderData?.data.period?.endDate,
				};
			}

			dispatchMatching({
				type: "SET_FILTERS",
				payload: newFilters,
			});
		};

		if (!isLoading && agencyData?.id && !initialAgencyFetched.current && agencies) {
			initialAgencyFetched.current = true;
			fetchInitialData();
		}
	}, [
		isLoading,
		agencies,
		agencyData?.id,
		filters,
		hasCommonQualificationId,
		commonQualification,
		orderData?.data.period?.startDate,
		orderData?.data.period?.endDate,
	]);

	const fetchSearchResults = useCallback(
		async ({ pageParam = 1 }) => {
			const results = await postMatchingOrderSearch(orderId, {
				limit: 100,
				page: pageParam,
				query: {
					...filters,
					// Handling "Toute" agencies
					agency: filters.agency === "all" ? null : filters.agency,
					commonDocuments: commonDocumentsIds,
				},
			});
			dispatchMatching({ type: "HIDE_WIDE_LOADING" });
			return results;
		},
		[filters]
	);

	const handleTitleCorrespondence = (title) => {
		if (title === "MR") return "M.";
		if (title === "MRS") return "Mme";
		return "";
	};

	const {
		data,
		error,
		isFetched: profilesAreFetched,
		isFetchingNextPage,
		hasNextPage,
		refetch: refetchProfiles,
		fetchNextPage,
		dataUpdatedAt: profilesUpdatedAt,
	} = useInfiniteQuery({
		retry: 0,
		queryKey: ["postMatchingOrderSearch", filters, orderId],
		queryFn: fetchSearchResults,
		getNextPageParam: (_, allPages) => {
			// if last page is detected, return undefined;
			if (allPages.length * 100 > allPages[0]?.data?.total) {
				return undefined;
			}
			// if list of pages exist, return the next page index
			if (allPages?.length > 0) {
				return allPages.length + 1;
			}
			// if no array exists, return the first page index
			return 1;
		},
		enabled:
			!!filters.agency &&
			!!filters.period?.startDate &&
			!!filters?.period?.endDate &&
			!!orderAgencyId &&
			(!!hasCommonQualificationId || hasFilters === true),
	});

	const [isDataStale, setIsDataStale] = useState(false);
	const POLLING_INTERVAL = 30000;
	const STALE_DELAY = 5 * 60 * 1000;

	useEffect(
		() => () => {
			dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
		},
		[pathname]
	);

	useEffect(() => {
		setIsDataStale(false);
		dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
	}, [profilesUpdatedAt, setIsDataStale]);

	const showStaleDataWarning = () => {
		dispatchApp({
			type: "SET_ORDER_MESSAGE",
			payload: {
				displayed: true,
				content: (
					<div>
						<Trans
							i18nKey="matching.staleDataWarning"
							components={{
								button: (
									<button
										className={style.infoLink}
										aria-label="rafraîchir"
										type="button"
										onClick={() => {
											dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
											dispatchMatching({
												type: "SET_WIDE_LOADING",
												payload: {
													isDisplayed: true,
													message: "Chargement en cours...",
												},
											});
											refetchProfiles();
										}}
									/>
								),
							}}
						/>
						<Picto
							onClick={() => {
								dispatchApp({ type: "CLEAR_ORDER_MESSAGE" });
							}}
							icon="close"
							className={style.pictoClose}
						/>
					</div>
				),
				color: "warning",
				withIcon: true,
			},
		});
	};

	useEffect(() => {
		const checkDataStaleness = () => {
			const now = new Date().getTime();
			if (now - profilesUpdatedAt > STALE_DELAY && !isDataStale) {
				setIsDataStale(true);
				showStaleDataWarning();
			}
		};

		if (!profilesUpdatedAt) return () => null;
		const interval = setInterval(checkDataStaleness, POLLING_INTERVAL);
		return () => clearInterval(interval);
	}, [profilesUpdatedAt, isDataStale]);

	const updateProfilesStatus = (unifiedTempWorkerIds) => {
		const profilsLength = unifiedTempWorkerIds.length;
		for (const id of unifiedTempWorkerIds) {
			updateOrderProfileStatus(id, PROFILES_STATUS.SELECTED);
		}
		setSelectableProfilesNumber(selectableProfilesNumber - profilsLength);
	};

	const concatenatedProfiles = useMemo(() => {
		if (!data) return [];
		if (profilesAreFetched) {
			// Concatenate all pages of result into one array
			return data.pages.reduce((acc, current) => [...acc, ...current.data.items], []);
		}
		return [];
	}, [data, profilesAreFetched]);

	useEffect(() => {
		setSelectableProfilesNumber(data?.pages?.[0]?.data?.selectableProfilesNumber);
	}, [agencyData, data?.pages?.[0]?.data?.selectableProfilesNumber]);

	const { mutate: addMatchedProfilesMutate, isLoading: addMatchedProfilesLoading } = useMutation(
		(selectedProfiles) =>
			postAddMatchedProfiles(orderId, {
				limit: selectedProfiles,
				query: {
					...filters,
					// Handling "Toute" agencies
					agency: filters.agency === "all" ? null : filters.agency,
					commonDocuments: commonDocumentsIds,
				},
			}),
		{
			onSuccess: (response) => {
				const { unifiedTempWorkerIds } = response?.data ?? {};
				updateProfilesStatus(unifiedTempWorkerIds);
				dispatchMatching({ type: "HIDE_WIDE_LOADING" });
			},
			onError: () => {
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t("global.apiCommandErrors.INTERNAL_SERVER_ERROR"),
					},
				});
				dispatchMatching({ type: "HIDE_WIDE_LOADING" });
			},
		}
	);

	const handleAddAllProfilesModalClick = (profilesCount) => {
		toggleModal("addAllProfiles", false, setModalStates);
		addMatchedProfilesMutate(profilesCount);
	};

	const handleCreateUserModalClick = () => {
		const agencyIdSource = agencies.find((agency) => agency.tempoFolderName === filters?.agency)?.id;
		toggleModal("createUser", false, setModalStates);
		if (filters.agency === "all") {
			toggleModal("createUserInterviewData", true, setModalStates);
			return;
		}
		createUserMutationMutate({ id: clickedProfile, agencyIdSource });
	};
	const handleCreateUserInterviewModalClick = (agencyIdSource) => {
		toggleModal("createUserInterviewData", false, setModalStates);
		createUserMutationMutate({ id: clickedProfile, agencyIdSource });
	};

	useEffect(() => {
		if (addMatchedProfilesLoading) {
			dispatchMatching({
				type: "SET_WIDE_LOADING",
				payload: {
					isDisplayed: true,
					message: "Ajout de profils en cours...",
				},
			});
		}
	}, [addMatchedProfilesLoading]);

	const updateOrderProfileStatus = (id, newStatus, isProfileLoading, hasUserOnReferenceAgencyOrder = true) => {
		queryClient.setQueryData(["postMatchingOrderSearch", filters, orderId], (queryData) => {
			if (queryData && Array.isArray(queryData?.pages)) {
				const updatedQueryData = {
					...queryData,
					pages: queryData?.pages.map((page) => {
						if (Array.isArray(page.data.items)) {
							const updatedPage = {
								...page,
								data: {
									...page.data,
									items: page.data.items.map((profile) =>
										profile.id === id
											? {
													...profile,
													orderProfileStatus: newStatus,
													isProfileLoading,
													hasUserOnReferenceAgencyOrder,
												}
											: profile
									),
								},
							};
							return updatedPage;
						}
						return page;
					}),
				};
				return updatedQueryData;
			}
			return queryData;
		});
	};

	const { mutate: profileSelectMutationMutate, isLoading: profileSelectMutationLoading } = useMutation(
		(id) => postSelectProfile(orderId, id),
		{
			onMutate: (id) => {
				updateOrderProfileStatus(id, null, true);
			},
			onSuccess: (response, id) => {
				setSelectableProfilesNumber(selectableProfilesNumber - 1);
				queryClient.invalidateQueries(["getOrderProfiles"]);
				if (response?.data?.status === 204) {
					toastDispatch({
						type: "ERROR",
						payload: {
							icon: "alertCircle",
							msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
						},
					});
					return;
				}
				updateOrderProfileStatus(id, "SELECTED", false);
			},
			onError: (e, id) => {
				const { response } = e;
				updateOrderProfileStatus(id, null, false);
				refetchProfiles();
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
					},
				});
			},
		}
	);

	const { mutate: profileDeselectMutationMutate, isLoading: profileDeselectMutationLoading } = useMutation(
		(id) => postDeslectProfile(orderId, id),
		{
			onMutate: (id) => {
				updateOrderProfileStatus(id, null, true);
			},
			onSuccess: (response, id) => {
				setSelectableProfilesNumber(selectableProfilesNumber + 1);
				queryClient.invalidateQueries(["getOrderProfiles"]);
				updateOrderProfileStatus(id, null, false);
			},
			onError: (e, id) => {
				updateOrderProfileStatus(id, null, false, false);
				refetchProfiles();
				const { response } = e;
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
					},
				});
			},
		}
	);

	const { mutate: createUserMutationMutate, isLoading: createUserMutationLoading } = useMutation(
		({ id, agencyIdSource }) => postCreateUser(clickedProfile, orderData?.data?.agencyId, agencyIdSource),
		{
			onMutate: (data) => {
				updateOrderProfileStatus(data.id, null, true, false);
			},
			onSuccess: (response, data) => {
				profileSelectMutationMutate(data.id);
				queryClient.invalidateQueries(["getMatchingTempWorkerInformations"]);
			},
			onError: (e, data) => {
				const { response } = e;
				refetchProfiles();
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${response?.data?.message}`),
					},
				});
				updateOrderProfileStatus(data.id, null, false, false);
			},
		}
	);

	const handleProfileClick = (id, orderProfileStatus, hasUserOnReferenceAgencyOrder, hasResidentPermit) => {
		if (profileSelectMutationLoading || profileDeselectMutationLoading || createUserMutationLoading) return;
		if (hasResidentPermit && !hasUserOnReferenceAgencyOrder && orderProfileStatus === null) {
			toggleModal("blockCreateUser", true, setModalStates);
			return;
		}
		if (!hasUserOnReferenceAgencyOrder) {
			setClickedProfile(id);
			toggleModal("createUser", true, setModalStates);
			return;
		}
		if (orderProfileStatus === null) {
			profileSelectMutationMutate(id);
			return;
		}
		if (orderProfileStatus === "SELECTED") {
			profileDeselectMutationMutate(id);
		}
	};

	useEffect(() => {
		dispatchMatching({
			type: "SET_INITIAL_FETCH",
			payload: {
				initialFetch: profilesAreFetched,
				hasCommonQualification: !!hasCommonQualificationId,
			},
		});
	}, [profilesAreFetched, hasCommonQualificationId]);

	useEffect(() => {
		if (error) {
			const message = MATCHING_ERRORS[error?.response?.data?.message] || MATCHING_ERRORS.DEFAULT;

			dispatchMatching({
				type: "SET_EMPTY_SECTION",
				payload: {
					isDisplayed: true,
					sectionType: message,
				},
			});
			dispatchMatching({ type: "HIDE_WIDE_LOADING" });
			return;
		}
		if (profilesAreFetched) {
			dispatchMatching({
				type: "SET_EMPTY_SECTION",
				payload: {
					isDisplayed: concatenatedProfiles?.length < 1,
					sectionType: MATCHING_ERRORS.DEFAULT,
				},
			});
		}
	}, [concatenatedProfiles, profilesAreFetched, error]);

	useEffect(() => {
		dispatchMatching({
			type: "UPDATE_SEARCH_RESULT_NUMBER",
			payload: data?.pages?.[0]?.data?.total || null,
		});
		if (!unifiedTempWorkerId) {
			dispatchMatching({
				type: "SET_UNIFIED_TEMP_WORKER_ID",
				payload: data?.pages?.[0]?.data?.items[0]?.id,
			});
		}
	}, [data]);

	const handleScroll = (e) => {
		const element = e.target;
		const atBottom = element.scrollHeight - element.scrollTop - 5 <= element.clientHeight;
		if (atBottom && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	};

	return {
		emptySection,
		handleScroll,
		handleProfileClick,
		handleTitleCorrespondence,
		handleAddAllProfilesModalClick,
		handleCreateUserModalClick,
		handleCreateUserInterviewModalClick,
		selectableProfilesNumber,
		concatenatedProfiles,
		isFetchingNextPage,
		agencyData,
		clickedProfile,
		modalStates,
		toggleModal,
		setModalStates,
	};
};

export default useMatchingResultList;
