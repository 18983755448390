import { FC, ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { TUserListing } from "src/types/TUsersListing";

import { InfoMessage } from "@zolteam/react-ras-library";

export type TTemporaryWorkerOnError = {
	error: string;
	firstName: string;
	id: number;
	lastName: string;
	tempoId: string;
	uniqueId: string;
};

interface IErrorOnActionProps {
	usersOnError: TTemporaryWorkerOnError[];
	allUser: TUserListing[];
}

const ErrorListItem = ({ names, uniqueId, tempoId }: { names: string; uniqueId: string; tempoId: string }) => {
	const { t } = useTranslation();
	return (
		<span key={`${names}`} className="table w-full table-fixed">
			<span className="table-row">
				<span className="table-cell w-[40%]">{names}</span>
				<span className="table-cell text-center w-[10%]">-</span>
				<span className="table-cell text-center w-[20%]">{uniqueId || t("global.undefined")}</span>
				<span className="table-cell text-center w-[10%]">-</span>
				<span className="table-cell w-[20%]">{tempoId || t("global.undefined")}</span>
			</span>
		</span>
	);
};

const ErrorOnAction: FC<IErrorOnActionProps> = ({ usersOnError, allUser }) => {
	const { t } = useTranslation();
	const usersOnErrorIds = new Set(usersOnError.map((item) => item.id));
	// 1. Grouper les utilisateurs par type d'erreur
	const errorsByType = usersOnError.reduce(
		(acc, user) => {
			const { error, firstName, lastName, tempoId, uniqueId } = user;

			if (!acc[error]) {
				acc[error] = [];
			}

			acc[error].push(<ErrorListItem names={`${firstName} ${lastName}`} uniqueId={uniqueId} tempoId={tempoId} />);
			return acc;
		},
		{} as { [key: string]: ReactNode[] }
	);

	return (
		<>
			{Object.entries(errorsByType).map(([errorType, userNames]) => (
				<div key={errorType}>
					<div className="mb-6 [&_div]:flex ">
						<InfoMessage color="error" withIcon>
							{t(`usersListing.apiResponse.errors.${errorType}`, { count: 2 })}
						</InfoMessage>
					</div>

					{userNames?.map((name, index) => <div key={index}>{name}</div>)}
				</div>
			))}

			{allUser?.length - usersOnError?.length > 0 && (
				<div>
					<div className="mb-6 [&_div]:flex ">
						<InfoMessage color="success" withIcon name="success">
							{t(`usersListing.apiResponse.success.updatedSheetsWithoutDelay`)}
						</InfoMessage>
					</div>
					{allUser
						.filter((user) => !usersOnErrorIds.has(user.id))
						.map((user) => {
							return <ErrorListItem names={user.name} uniqueId={user.uniqueId} tempoId={user.tempoId} />;
						})}
				</div>
			)}
		</>
	);
};

export default ErrorOnAction;
