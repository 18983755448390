import { FC } from "react";

import { t } from "i18next";
import { TPlanningFilter, TWeekDays } from "src/types";

import { FilterCardHeader } from "src/components/molecules";
import { CandidatPlanningRow } from "src/components/molecules/CandidatPlanningRow/CandidatPlanningRow";

const days: TWeekDays[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

interface ICandidatPlanningFilterProps {
	onChange: (planning: TPlanningFilter) => void;
	values: TPlanningFilter;
	handleReset: () => void;
}

export const checkActivesPlanningItems = (values: TPlanningFilter) => {
	const actives = days.map((day) => {
		const row = values[day];
		return row.morning || row.afternoon || row.night;
	});
	return actives.includes(true);
};

export const CandidatPlanningFilter: FC<ICandidatPlanningFilterProps> = ({ onChange, values, handleReset }) => {
	return (
		<div className="max-w-[400px]">
			<FilterCardHeader title={t("usersListing.filters.planning.title")} handleReset={handleReset} />
			<table className="w-full">
				<thead>
					<tr className="[&>th]:pb-2 [&>th]:text-xs [&>th]:font-normal text-neutral-500">
						<th></th>
						<th>7H-14H</th>
						<th>14H-21H</th>
						<th>21H-7H</th>
					</tr>
				</thead>
				<tbody>
					{days.map((day, index) => (
						<CandidatPlanningRow
							key={day}
							label={t("global.days." + (index + 1))}
							values={values[day]}
							onChange={(rowValues) => {
								onChange({
									...values,
									[day]: rowValues,
								});
							}}
							name={`planning.${day}`}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};
