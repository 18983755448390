// @ts-nocheck
import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Route, Switch, useLocation } from "react-router-dom";

// Side Menu Views
import ProfileMissions from "./ProfileMissions/ProfileMissions";
import ProfileMissionsClientRelationshipForm from "./ProfileMissions/ProfileMissionsClientRelationships/forms/ProfileMissionsClientRelationshipForm";
import ProfileMissionsFollowUpForm from "./ProfileMissions/ProfileMissionsFollowUp/forms/ProfileMissionsFollowUpForm";
import { BackLink, Text } from "@zolteam/react-ras-library";
// Views
import { WithSideMenu, WithTopBarre } from "src/components/templates";
import ProfileSideMenu from "src/components/templates/ProfileSideMenu";

// Constants
import { CREATE_MODE, EDIT_MODE } from "src/constants";

const Profile = () => {
	// Hooks
	const { t } = useTranslation();
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const id = queryParams.get("id");

	// Side Menu Routing
	const routes = useMemo(
		() => [
			{
				path: "/profile/:profileId/missions/follow-up/create",
				routeName: "profile-missions-follow-up-create",
				label: null,
				exact: true,
				component: <ProfileMissionsFollowUpForm mode={CREATE_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions/follow-up/:followUpId",
				routeName: "profile-missions-follow-up-edit",
				label: null,
				exact: true,
				component: <ProfileMissionsFollowUpForm mode={EDIT_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions/client-relationship/create",
				routeName: "profile-missions-client-relationship-create",
				label: null,
				exact: true,
				component: <ProfileMissionsClientRelationshipForm mode={CREATE_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions/client-relationship/:relationshipId",
				routeName: "profile-missions-client-relationship-edit",
				label: null,
				exact: true,
				component: <ProfileMissionsClientRelationshipForm mode={EDIT_MODE} />,
				showInMenu: false,
			},
			{
				path: "/profile/:profileId/missions",
				routeName: "profile-missions",
				label: "Missions",
				exact: false,
				component: <ProfileMissions />,
				showInMenu: true,
			},
		],
		[]
	);

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={null}
				leftContent={
					<div style={{ display: "flex" }}>
						<BackLink>
							<a
								href={
									id
										? `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/contacts/${id}`
										: `${process.env.REACT_APP_BASE_OLD_URL}/#/fr/contacts`
								}
								target="_blank"
								rel="noreferrer"
							>
								<Text fontWeight="normal" tag="span">
									{id
										? t("profile.backLinkToUnifiedTempWorker")
										: t("profile.backLinkToTempWorkerPool")}
								</Text>
							</a>
						</BackLink>
					</div>
				}
				title={null}
				containerClassname="container-with-sidemenu"
			>
				<ProfileSideMenu routes={routes.filter((route) => route.showInMenu)}>
					<Switch>
						{routes.map((route) => (
							<Route key={route.path} exact={route.exact} path={`${route.path}`}>
								{route.component}
							</Route>
						))}
					</Switch>
				</ProfileSideMenu>
			</WithTopBarre>
		</WithSideMenu>
	);
};

Profile.propTypes = {};

export default Profile;
