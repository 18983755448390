import { FC, useMemo, useState } from "react";

import { AxiosError } from "axios";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import i18n from "src/i18n";
import { TApiError } from "src/types/TErrors";
import { TUserListing } from "src/types/TUsersListing";

import usersListingService from "src/services/usersListingService";

import ErrorOnAction, { TTemporaryWorkerOnError } from "./ErrorOnAction";
import { InfoMessage } from "@zolteam/react-ras-library";
import { ConfirmModal } from "src/components/molecules/ConfirmModal/ConfirmModal";
import { CheckboxListFilter } from "src/components/organisms";

import { OUT_BUSINESS_REASONS_MOCKED } from "src/constants/CUsersListing";

interface ActionOutOfBuisnessProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	selectedItem: TUserListing[];
}

const DEFAULT_OUT_BUSINESS_REASONS = "OUT_BUSINESS_OTHER";

const ActionOutOfBuisness: FC<ActionOutOfBuisnessProps> = ({ isOpen, setIsOpen, selectedItem }) => {
	const { t } = useTranslation();
	const [outOfBuisnessReason, setOutOfBuisnessReason] = useState(DEFAULT_OUT_BUSINESS_REASONS);
	const queryClient = useQueryClient();
	const [usersOnError, setUsersOnError] = useState<TTemporaryWorkerOnError[]>([]);

	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: "getOutOfBuisnessReasons",
		queryFn: () => usersListingService.getOutOfBuisnessReasons(),
		staleTime: Infinity,
		enabled: isOpen,
	});

	const sortedData = useMemo(() => {
		return data ? sortBy(data, (item) => item.value !== 10) : [];
	}, [data]);

	const mutationActionUpdateOutOfBuisnessReason = useMutation(
		({ userIds, data }: { userIds: number[]; data: string }) =>
			usersListingService.updateOutOfBuisnessReason(userIds, data),
		{
			onSuccess: (data) => {
				if (data?.status === 207 && selectedItem?.length > 1) {
					setUsersOnError(data.data);
				} else {
					queryClient.invalidateQueries({ queryKey: ["postUsersListingSearch"] });
					toast.success(
						selectedItem?.length === 1
							? t(`usersListing.apiResponse.success.updatedSheet`)
							: t(`usersListing.apiResponse.success.updatedSheets`)
					);
				}
			},
			onError: (error: AxiosError<TApiError>) => {
				const message = error?.response?.data?.message;
				if (i18n.exists(`usersListing.apiResponse.errors.${message}`)) {
					toast.error(
						t(`usersListing.apiResponse.errors.${message}`, {
							name: selectedItem[0]?.name,
							count: selectedItem?.length === 1 ? 1 : 2,
						})
					);
				} else {
					toast.error(t(`global.apiErrors.undefined`));
				}
			},
			onSettled: (data) => {
				setOutOfBuisnessReason(DEFAULT_OUT_BUSINESS_REASONS);
				if (data?.status !== 207) {
					setIsOpen(false);
				}
			},
		}
	);

	const handleUpdateOutOfBuisnessReason = (userIds: number[], outOfBusinessReasonName: string) => {
		return mutationActionUpdateOutOfBuisnessReason.mutateAsync({
			userIds,
			data: data?.find((d) => d?.name?.toString() === outOfBusinessReasonName)?.value?.toString(),
		});
	};

	return (
		<ConfirmModal
			title={t("usersListing.primaryActions.status.title")}
			isOpen={isOpen}
			onClose={() => {
				setUsersOnError([]);
				setIsOpen(false);
				setOutOfBuisnessReason(DEFAULT_OUT_BUSINESS_REASONS);
			}}
			onConfirm={() => {
				return handleUpdateOutOfBuisnessReason(selectedItem.map((sI) => sI.id) || null, outOfBuisnessReason);
			}}
			confirmText={
				selectedItem?.length > 1
					? t("usersListing.primaryActions.status.confirmMulti")
					: t("usersListing.primaryActions.status.confirm")
			}
			size="s"
			className={`!w-max ${usersOnError?.length > 0 && "[&>*:nth-child(2)]:max-h-[80dvh] [&>*:nth-child(2)]:overflow-y-auto [&>*:nth-child(2)]:overflow-x-hidden"} `}
			disableConfirm={!data}
			buttons={usersOnError?.length > 0 ? ["", ""] : null}
			closeOnConfirm={false}
		>
			{isLoading || isFetching ? (
				<div className="flex flex-col gap-1">
					{OUT_BUSINESS_REASONS_MOCKED?.map((obr) => <Skeleton key={obr.value} height={45} width={"100%"} />)}
				</div>
			) : error ? (
				<div className="[&>div]:w-full [&>div]:box-border">
					<InfoMessage withIcon color="error">
						{t("global.error")}
					</InfoMessage>
				</div>
			) : (
				<div className="flex flex-col gap-8">
					{usersOnError?.length > 0 ? (
						<div className="max-w-96 flex flex-col gap-8">
							<ErrorOnAction usersOnError={usersOnError} allUser={selectedItem} />
						</div>
					) : (
						<>
							{selectedItem?.length > 1 && (
								<div className="max-w-96 [&_span]:overflow-auto [&_span]:max-h-20 [&_span]:items-start">
									<InfoMessage color={"warning"} withIcon>
										{t("usersListing.primaryActions.status.warnTextMulti", {
											workers: selectedItem?.map((item) => `${item.name}`).join(", "),
										})}
									</InfoMessage>
								</div>
							)}
							<CheckboxListFilter
								items={sortedData?.reduce<Record<string, boolean>>((acc, reason) => {
									acc[String(reason?.name)] = false; // Convertir la clé en chaîne
									return acc;
								}, {})}
								type="radio"
								onChange={(item) => setOutOfBuisnessReason(item)}
								getLabel={(item) => {
									return t(`usersListing.primaryActions.status.OUT_BUSINESS_REASONS.${item}`);
								}}
								getValue={(type) => {
									return type === outOfBuisnessReason;
								}}
								listStyle={{ gap: "0.75rem" }}
								labelClassName="gap-3 py-3 pl-2 pr-0 rounded-lg w-full cursor-pointer"
								selectedLabelClassName="bg-primary-50 text-primary-500"
							/>
						</>
					)}
				</div>
			)}
		</ConfirmModal>
	);
};

export default ActionOutOfBuisness;
