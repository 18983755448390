import { FormikErrors, FormikTouched } from "formik";
import { get, isEmpty } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";

export const showWorkingTimeTooltipError = (
	touched: FormikTouched<OrderInterface>,
	errors: FormikErrors<OrderInterface>
): boolean => {
	const isWeeklyHoursError =
		get(touched?.workingTime, "weeklyHours", false) && !isEmpty(errors.workingTime?.weeklyHours);
	const isSlotsError = get(touched?.workingTime, "slots", false) && !isEmpty(errors.workingTime?.slots);
	return isWeeklyHoursError || isSlotsError;
};
