import { pick, get, isEqual } from "lodash";

export const haveSpecificNestedFieldsChanged = <ObjectInterface>(
	values: ObjectInterface,
	initialValues: ObjectInterface,
	fieldsToCompare: {
		path: string;
		fields: string[];
	}[]
) => {
	return fieldsToCompare.some(({ path, fields }) => {
		const currentSubset = pick(get(values, path), fields);
		const initialSubset = pick(get(initialValues, path), fields);

		return !isEqual(currentSubset, initialSubset);
	});
};
