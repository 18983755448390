import { useState, useEffect, useMemo } from "react";

import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { useFormikContext } from "formik";
import { omit, set } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMedia } from "react-use";
// Types
import { OrderInterface } from "src/types/OrderInterface";

import { DEFAULT_DAY_WORKING_TIME, DEFAULT_NIGHT_WORKING_TIME } from "./constants";
import { areAllDaySelected } from "./utils/areAllDaySelected";
import { autoCompleteWeeklyHours } from "./utils/autoCompleteWeeklyHours";
import { getNewValuesOnModeChanged } from "./utils/getNewValuesOnModeChanged";
import { getWeeklyHoursDisplayMessages } from "./utils/weeklyHoursDisplayRules";
import { selectDefaultWorkingDays } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/selectDefaultWorkingDays";

// Hooks
// Utils
import { getDayOfWeek } from "src/utils/dates";
import { haveSpecificNestedFieldsChanged } from "src/utils/objects";

// Constants
import { WORK_PERIOD_TYPES, CREATE_MODE, INDEX_DAYS } from "src/constants";
import { DAYS_INDEXES_TABLE } from "src/constants/daysIndexesTable";
import { NIGHT_DAYS_INDEXES_TABLE } from "src/constants/nightDaysIndexesTable";
import { WORKING_MODE } from "src/constants/workingMode";

interface RouteParams {
	orderId: string;
}

type Params = {
	isOrderFetched: boolean;
};

const weeklyHoursMask: MaskitoOptions = {
	mask: /^\d+([.,]\d{0,2})?$/,
	preprocessors: [
		({ elementState, data }, actionType) => {
			const { value, selection } = elementState;

			return {
				elementState: {
					selection,
					value: value.replace(".", ","),
				},
				data: data.replace(".", ","),
			};
		},
	],
};

const useCommandTimeFormBlock = ({ isOrderFetched }: Params) => {
	const { t } = useTranslation();
	const { orderId } = useParams<RouteParams>();

	const weeklyInputRef = useMaskito({ options: weeklyHoursMask });

	const showOneColumn = useMedia("(max-width: 1439px)");
	const showFullSize = useMedia("(min-width: 1920px)");

	const [focusedInputName, setFocusedInputName] = useState("");

	const { initialValues, values, setValues, setFieldValue, setFieldTouched, touched, errors } =
		useFormikContext<OrderInterface>();

	const { workPeriodType, period, workingTime } = values;

	const translatedDays = useMemo(() => INDEX_DAYS.map((index) => t(`global.days.${index}`)), [INDEX_DAYS]);

	const isNight = useMemo(() => workPeriodType === WORK_PERIOD_TYPES.NIGHT, [workPeriodType]);

	const tabs = useMemo(
		() => [
			{
				id: WORK_PERIOD_TYPES.DAY,
				label: t("commands.periodForm.day"),
			},
			{
				id: WORK_PERIOD_TYPES.NIGHT,
				label: t("commands.periodForm.night"),
			},
		],
		[t]
	);

	const currentTab = useMemo(() => {
		if (!workPeriodType) {
			return null;
		}
		return tabs.find((tab) => tab.id === workPeriodType).label;
	}, [workPeriodType]);

	// For now, switching between day and night will reset workingTime values
	const handleTabChange = async (index: number) => {
		const periodType = tabs[index].id;
		if (periodType === workPeriodType) {
			return;
		}

		setValues(getNewValuesOnModeChanged(periodType, values, isNewCommand));
	};

	const isNewCommand = orderId === CREATE_MODE;

	const startDateDayIndex = useMemo(() => {
		if (!period?.startDate) {
			return null;
		}
		return getDayOfWeek(new Date(period?.startDate));
	}, [period?.startDate]);

	const areAllDaysSelected: boolean = useMemo(() => {
		return areAllDaySelected(isNight, values.workingTime);
	}, [values.workingTime, isNight]);

	const workingDaysToIndexesArray = useMemo(() => {
		const days = isNight
			? { ...DEFAULT_NIGHT_WORKING_TIME.workingNights, ...workingTime?.workingNights }
			: { ...DEFAULT_DAY_WORKING_TIME.workingDays, ...workingTime?.workingDays };

		return Object.values(days || {})
			.map((isSelected, index) => (isSelected ? index + 1 : null)) // +1 is using for lib DaySelectors
			.filter(Boolean);
	}, [workingTime.workingDays, workingTime.workingNights, isNight]);

	const handleClearPeriodDate = (periodSlot: string, event: React.SyntheticEvent) => {
		event.stopPropagation();
		const newValues = Object.assign({}, values, {
			workingTime: omit(
				{
					...values.workingTime,
					...(isNight ? DEFAULT_NIGHT_WORKING_TIME : DEFAULT_DAY_WORKING_TIME),
				},
				[isNight ? WORKING_MODE.DAYS : WORKING_MODE.NIGHTS]
			),
		});

		set(newValues, periodSlot, null);
		setValues(newValues);
		setFieldTouched(periodSlot, true);
	};

	const handleDateRangeUpdate = (startDate?: moment.Moment | null, endDate?: moment.Moment | null) => {
		const newPeriod = {
			// WARNING: those dates are transformed when sent to the API (because they don't accept ISO format on POST/PUT)
			// If you rewrite those lines, watch out for the POST/PUT API call in Configuration.jsx
			startDate: startDate?.toISOString() || null,
			endDate: endDate?.toISOString() || null,
		};

		const newWorkingDays = selectDefaultWorkingDays(startDate, endDate, workPeriodType);

		if (startDate) {
			setFieldTouched("period.startDate", true);
		}
		if (endDate) {
			setFieldTouched("period.endDate", true);
		}

		return setValues({
			...values,
			period: newPeriod,
			workingTime: {
				...values.workingTime,
				[isNight ? "workingNights" : "workingDays"]: newWorkingDays,
			},
		});
	};

	const handleDayClick = (selectedDayIndex: number) => {
		if (selectedDayIndex === startDateDayIndex) {
			return;
		}
		// The API use an object of days set with bool, but the component uses indexes and array. So we need
		// some dumb logic to match both of their behaviour.
		let weekDaySelected: string;

		if (isNight) {
			weekDaySelected = NIGHT_DAYS_INDEXES_TABLE[selectedDayIndex];
			setFieldValue("workingTime.workingNights", {
				...values.workingTime?.workingNights,
				// @ts-ignore
				[weekDaySelected]: !values.workingTime?.workingNights[weekDaySelected],
			});
			setFieldTouched("workingTime.workingNights", true);
		} else {
			weekDaySelected = DAYS_INDEXES_TABLE[selectedDayIndex];
			setFieldValue("workingTime.workingDays", {
				...values.workingTime?.workingDays,
				// @ts-ignore
				[weekDaySelected]: !values.workingTime?.workingDays[weekDaySelected],
			});
			setFieldTouched("workingTime.workingDays", true);
		}
	};

	const handleChangeWorkHours = (e: { name: string; value: object }) => {
		const [slotLabel, slotIndex] = e.name.split("-");
		const newWorkingTimeSlots = workingTime.slots.map((slotValue, index) => {
			if (index === Number(slotIndex)) {
				return { ...slotValue, [slotLabel]: e.value || null };
			}
			return slotValue;
		});

		setFieldValue("workingTime.slots", newWorkingTimeSlots);
		setTimeout(() => {
			setFieldTouched("workingTime.slots", true, true);
			setFieldTouched("period.startDate", true, true);
			setFieldTouched("period.endDate", true, true);
		}, 0);
	};

	const handleWeeklyHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setWeeklyHoursBeenEdited(true);
		setFieldTouched("workingTime.weeklyHours", true);
		setFieldValue("workingTime.weeklyHours", e.target.value);
	};

	useEffect(() => {
		if (!isNewCommand) {
			setTimeout(() => {
				setFieldTouched("workingTime.slots", true, true);
			}, 0);
		}
	}, [isNewCommand, setFieldTouched, values]);

	useEffect(() => {
		const weekDaySelected = isNight
			? NIGHT_DAYS_INDEXES_TABLE[startDateDayIndex]
			: DAYS_INDEXES_TABLE[startDateDayIndex];

		requestAnimationFrame(async () => {
			if (isNight) {
				setFieldValue("workingTime.workingNights", {
					...values.workingTime?.workingNights,
					[weekDaySelected]: true,
				});
			} else {
				setFieldValue("workingTime.workingDays", {
					...values.workingTime?.workingDays,
					[weekDaySelected]: true,
				});
			}
		});
	}, [startDateDayIndex, currentTab]);

	// Weekly hours autocomplete
	const [isWeeklyHoursFocused, setIsWeeklyHoursFocused] = useState(false);
	const [weeklyHoursBeenEdited, setWeeklyHoursBeenEdited] = useState(!isNewCommand);

	useEffect(() => {
		const { period } = values;
		const { slots, workingDays, workingNights, weeklyHours } = values.workingTime;

		if (isWeeklyHoursFocused) {
			return;
		}
		if (isNewCommand) {
			const areSlotsFilled = slots.length > 0 && slots.every((slot) => slot.startHour && slot.endHour);
			if (!weeklyHoursBeenEdited && period.startDate && period.endDate && areSlotsFilled) {
				const calculatedHours = autoCompleteWeeklyHours(period, slots, workingDays, workingNights);
				setFieldValue(
					"workingTime.weeklyHours",
					parseFloat(calculatedHours.toFixed(2)?.replace(".", ",") || "")
				);
			}
		}

		// Handle case when the field is emptied and focus is lost in both modes
		const areSlotsFilled = slots.length > 0 && slots.every((slot) => slot.startHour && slot.endHour);
		if (
			!isNewCommand &&
			!weeklyHours &&
			touched.workingTime?.weeklyHours &&
			period.startDate &&
			period.endDate &&
			areSlotsFilled
		) {
			const calculatedHours = autoCompleteWeeklyHours(period, slots, workingDays, workingNights);
			setFieldValue("workingTime.weeklyHours", parseFloat(calculatedHours.toFixed(2)?.replace(".", ",") || ""));
		}
	}, [values, touched, setFieldValue, isWeeklyHoursFocused, weeklyHoursBeenEdited]);

	const getOnDeleteSlotsLine = (indexToDelete: number, params: { softDelete?: boolean }) => {
		const { softDelete } = params || {};
		setFieldTouched("workingTime.slots", true);

		if (softDelete) {
			setFieldValue(
				"workingTime.slots",
				workingTime.slots.map((slotValue, index) =>
					index === indexToDelete ? { startHour: null, endHour: null } : slotValue
				)
			);
			return;
		}

		setFieldValue(
			"workingTime.slots",
			workingTime.slots.filter((_, index) => index !== indexToDelete)
		);
	};

	const weeklyHoursFieldsToCompare = [
		{
			path: "period",
			fields: ["startDate", "endDate"],
		},
		{
			path: "workingTime",
			fields: ["workingDays", "workingNights", "slots"],
		},
	];

	const hasWeeklyHoursDependentFieldsChanged = useMemo(
		() => haveSpecificNestedFieldsChanged<OrderInterface>(values, initialValues, weeklyHoursFieldsToCompare),
		[values, initialValues]
	);

	const weeklyHoursDisplayMessages = useMemo(() => {
		return getWeeklyHoursDisplayMessages(
			!isNewCommand,
			values.workingTime?.weeklyHours,
			values.period,
			hasWeeklyHoursDependentFieldsChanged
		);
	}, [
		hasWeeklyHoursDependentFieldsChanged,
		values.workingTime?.weeklyHours,
		values.period,
		touched.period?.endDate,
		touched.period?.startDate,
		touched.workingTime?.workingDays,
		touched.workingTime?.workingNights,
		touched.workingTime?.slots,
	]);

	const isBlockLoading = (!isOrderFetched && !isNewCommand) || !currentTab;

	return {
		t,
		isBlockLoading,
		startDateDayIndex,
		tabs,
		currentTab,
		showOneColumn,
		showFullSize,
		focusedInputName,
		translatedDays,
		isNight,
		areAllDaysSelected,
		workingDaysToIndexesArray,
		values,
		touched,
		errors,
		weeklyHoursDisplayMessages,
		weeklyInputRef,
		setFocusedInputName,
		handleTabChange,
		handleClearPeriodDate,
		handleDateRangeUpdate,
		handleDayClick,
		handleChangeWorkHours,
		handleWeeklyHoursChange,
		getOnDeleteSlotsLine,
		setFieldValue,
		setFieldTouched,
		setIsWeeklyHoursFocused,
		setWeeklyHoursBeenEdited,
	};
};

export default useCommandTimeFormBlock;
