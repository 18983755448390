import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { NavLink, Redirect, Route, Switch, useParams, useHistory, useLocation } from "react-router-dom";

// Utils
import cn from "../../../utils/cn";
// Views
import ProfileMissionsClientRelationships from "./ProfileMissionsClientRelationships";
import ProfileMissionsContracts from "./ProfileMissionsContracts";
import ProfileMissionsFollowUp from "./ProfileMissionsFollowUp";
import ProfileMissionsOrders from "./ProfileMissionsOrders";
import { Button, Picto } from "@zolteam/react-ras-library";

import style from "./ProfileMissions.module.css";

const ProfileMissions = () => {
	const { profileId } = useParams<ProfileMissionsOrdersParams>();
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { t } = useTranslation();
	const lastPathname = pathname.split("/").pop();

	const translationKeys: { [key: string]: string } = {
		"follow-up": "profile.addMissionFollowUp",
		"client-relationship": "profile.addClientRelationShip",
	};

	const translationKey = translationKeys[lastPathname] || null;

	const routes = useMemo(
		() => [
			{
				path: "/profile/:profileId/missions/orders",
				routeName: "profile-missions-orders",
				label: "Commandes",
				component: <ProfileMissionsOrders />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/follow-up",
				routeName: "profile-missions-follow-up",
				label: "Suivis de mission",
				component: <ProfileMissionsFollowUp />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/client-relationship",
				routeName: "profile-missions-client-relationship",
				label: "Relations clients",
				component: <ProfileMissionsClientRelationships />,
				enabled: true,
			},
			{
				path: "/profile/:profileId/missions/contracts",
				routeName: "profile-missions-contracts",
				label: "Contrats",
				component: <ProfileMissionsContracts />,
				enabled: true,
			},
		],
		[]
	);

	return (
		<div className={style.profileMissions}>
			<div className={style.menu}>
				<div className="flex items-center justify-between h-10">
					<div>
						{routes.map((route) => (
							<NavLink
								key={route.path}
								className={cn([style.menuItem, !route.enabled ? style.routeDisabled : null])}
								to={{
									pathname: route.path.replace(":profileId", profileId),
									search,
								}}
								activeClassName={style.selected}
							>
								<button type="button" key={route.path} className={style.menuItemBtn}>
									{route.label}
								</button>
							</NavLink>
						))}
					</div>
					{lastPathname === "follow-up" || lastPathname === "client-relationship" ? (
						<div className={style.header}>
							<div className={style.tableOptions}>
								<Button
									type="button"
									onClick={() =>
										history.push(`/profile/${profileId}/missions/${lastPathname}/create`)
									}
									color="primary"
								>
									{t(translationKey)}
								</Button>
								<div className={style.filter}>
									<Picto icon="sliders" className={style.filterPicto} />
									Filtrer
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className={style.content}>
				<Switch>
					<Route exact path="/profile/:profileId/missions">
						<Redirect to={`/profile/${profileId}/missions/orders`} />
					</Route>
					{routes.map((route) => (
						<Route key={route.path} exact path={`${route.path}`}>
							{route.component}
						</Route>
					))}
				</Switch>
			</div>
		</div>
	);
};

ProfileMissions.propTypes = {};

export default ProfileMissions;
