// @ts-nocheck
import { useTranslation } from "react-i18next";
import { useTitle } from "react-use";

// Types
import { UnifiedTempWorkerOrdersSearchItemInterface } from "src/services/UnifiedTempWorker/unifiedTempWorkerOrdersSearchService";

import { DEFAULT_SEARCH_PARAMS } from "./constants";
import useProfileMissionsOrders from "./useProfileMissionsOrders";
// Hooks
import { PaginationV2, Picto, SelectPopover, Tag, Text, Tooltip } from "@zolteam/react-ras-library";
// Molecules
import { ColumnListing, DateTimeRangeDisplay, PaginationSearch, TableNumberRow } from "src/components/molecules";
// Components
import { TableListing } from "src/components/organisms";

// Utils
import cn from "src/utils/cn";
import { formatDate } from "src/utils/formatDate";
import { definePageOffset } from "src/utils/paginationUtils";

// Constants
import { PAGINATION_OFFSET_OPTIONS } from "src/constants";
import { DATE_FORMAT_SHORT_WITH_MONTH } from "src/constants/dates";

import style from "./ProfileMissionsOrders.module.css";

const ProfileMissionsOrders = () => {
	const { t } = useTranslation();

	useTitle(t("profile.missions.title"));

	const {
		data,
		isErrorSearchMutation,
		isListLoading,
		getProfileStatusColor,
		getOrderStatusColor,
		handleSearch,
		handleDoubleClick,
		searchParams,
		getTableRowHref,
	} = useProfileMissionsOrders();

	return (
		<div className={style.content}>
			<div className={style.listView}>
				<TableListing
					loading={isListLoading()}
					canBeChecked={false}
					error={isErrorSearchMutation}
					data={data.items}
					fullHeight
					handleSearch={handleSearch}
					onDoubleClick={handleDoubleClick}
					getTableRowHref={getTableRowHref}
					disabledRow={(elem) => !elem.canEditOrder}
					initialColumnSort={{
						field: DEFAULT_SEARCH_PARAMS.sorting,
						orderBy: DEFAULT_SEARCH_PARAMS.orderBy,
					}}
					loadingMessage={t("commands.loadingMessage")}
					footer={
						<div className={style.footer}>
							{data.total ? (
								<div className={style.pagination}>
									<PaginationV2
										previousLabel={<Picto icon="chevronLeft" />}
										nextLabel={<Picto icon="chevronRight" />}
										breakLine={
											<PaginationSearch
												onInputChange={handleSearch}
												max={Math.round(data.total / searchParams.limit)}
											/>
										}
										currentPage={searchParams.page}
										totalCount={data.total}
										pageSize={searchParams.limit}
										siblingCount={2}
										theme={"primary"}
										onPageChange={(activePage: number) => handleSearch({ page: activePage })}
									/>
									<span className={style.resultsPagination}>
										{t("global.pagination.resultsPagination", {
											resultStart:
												searchParams.limit * searchParams.page - searchParams.limit + 1,
											resultEnd:
												searchParams.limit * searchParams.page <= data?.total
													? searchParams.limit * searchParams.page
													: data?.total,
											resultTotal: data?.total,
										})}
									</span>
									<SelectPopover
										name="limit"
										options={PAGINATION_OFFSET_OPTIONS}
										theme={"primary"}
										onChange={(value: number) =>
											handleSearch({
												limit: value,
												page: definePageOffset(value, searchParams.limit, searchParams.page),
											})
										}
										value={
											searchParams.limit
												? PAGINATION_OFFSET_OPTIONS.find(
														(option) => option.value === searchParams.limit
													)
												: null
										}
									/>
								</div>
							) : null}
						</div>
					}
				>
					<ColumnListing
						id="orderStatus"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.status")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({ status: elem.orderStatus })}
						component={({ data: { status } }: any) => (
							<Tag className={style.tag} color={getOrderStatusColor(status)}>
								{t(`profile.missions.orderStatuses.${status}`)}
							</Tag>
						)}
						tdClassName={cn(["min-column-width", style.orderStatusCell])}
					/>
					<ColumnListing
						id="tempoId"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.tempoId")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({
							id: elem.orderTempoId,
							type: elem.orderType,
						})}
						component={({ data: { id, type } }) =>
							id && <TableNumberRow number={`${id}`} type={t(`profile.missions.orderTypes.${type}`)} />
						}
						tdClassName="min-column-width"
					/>
					<ColumnListing
						id="clientName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.client")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({
							clientName: elem.clientSocialReason,
						})}
						component={({ data: { clientName } }) => (
							<Text tag="span" size="paragraph02">
								{clientName}
							</Text>
						)}
					/>
					<ColumnListing
						id="qualification"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.qualification")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({
							qualificationName: elem.clientQualificationName,
						})}
						component={({ data: { qualificationName } }) => (
							<>
								<Text tag="span" size="paragraph02">
									{qualificationName || ""}
								</Text>
							</>
						)}
					/>
					<ColumnListing
						id="startDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.startDate")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({
							start: elem.orderStartDate,
							status: elem.orderStatus,
						})}
						component={({ data: { start, firstDayWorkHours, status } }) => (
							<DateTimeRangeDisplay
								locked={false}
								date={formatDate(start, DATE_FORMAT_SHORT_WITH_MONTH)}
								onClick={() => {}}
							/>
						)}
						tdClassName="min-column-width"
					/>
					<ColumnListing
						id="endDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.endDate")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({ end: elem.orderEndDate })}
						component={({ data: { end } }) => (
							<DateTimeRangeDisplay
								locked={false}
								date={formatDate(end, DATE_FORMAT_SHORT_WITH_MONTH)}
								onClick={() => {}}
							/>
						)}
						tdClassName="min-column-width"
					/>
					<ColumnListing
						id="agencyName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("commands.agency")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({ agencyName: elem.agencyName })}
						component={({ data: { agencyName } }) => (
							<Text tag="span" size="paragraph02">
								{agencyName || ""}
							</Text>
						)}
					/>
					<ColumnListing
						id="profileStatus"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.missions.listHeaders.profileStatus")}
							</Text>
						}
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({
							status: elem.orderProfileStatus,
						})}
						component={({ data: { status } }) => (
							<Tag className={style.tag} color={getProfileStatusColor(status)}>
								{t(`profile.missions.profileStatuses.${status}`)}
							</Tag>
						)}
						tdClassName="min-column-width"
					/>
					<ColumnListing
						id="lock"
						name=""
						field={(elem: UnifiedTempWorkerOrdersSearchItemInterface) => ({
							canEditOrder: elem.canEditOrder,
						})}
						component={({ data: { canEditOrder } }) =>
							!canEditOrder ? (
								<Tooltip arrow={false} placement="top" content={t("profile.missions.blocked")}>
									<div>
										<Picto className={style.lockPicto} icon="lockRegular" />
									</div>
								</Tooltip>
							) : null
						}
						tdClassName="min-column-width"
					/>
				</TableListing>
			</div>
		</div>
	);
};

ProfileMissionsOrders.propTypes = {};

export default ProfileMissionsOrders;
