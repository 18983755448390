import { FC, PropsWithChildren } from "react";

import cn from "../../../utils/cn";
import { InfoMessage, Tooltip } from "@zolteam/react-ras-library";

import style from "./TooltipMessage.module.css";

interface ITooltipMessageProps extends PropsWithChildren {
	color?: string;
	className?: string;
	arrow?: boolean;
	placement?: "top" | "bottom" | "left" | "right";
	tooltipBoxStyle?: React.CSSProperties;
}

export const TooltipMessage: FC<ITooltipMessageProps> = ({
	className = "",
	color = "primary",
	children = "",
	arrow = true,
	placement = "top",
	tooltipBoxStyle = {},
}) => (
	<Tooltip
		placement={placement}
		animation={false}
		arrow={arrow}
		tooltipBoxStyle={tooltipBoxStyle}
		content={<span>{children}</span>}
	>
		<div className={cn([style.infoMessage, className])}>
			<InfoMessage color={color} withIcon className={style.infoMessage} />
		</div>
	</Tooltip>
);
