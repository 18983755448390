import i18n from "i18next";
import { isEmpty } from "lodash";

const calculateWeeksBetweenDates = (startDate: string, endDate: string): number => {
	const start = new Date(startDate);
	const end = new Date(endDate);
	return Math.ceil((end.getTime() - start.getTime()) / (7 * 24 * 60 * 60 * 1000));
};

export const isPeriodLessThan12Weeks = (startDate: string, endDate: string): boolean => {
	return calculateWeeksBetweenDates(startDate, endDate) <= 12;
};

export const isPeriodMoreThan12Weeks = (startDate: string, endDate: string): boolean => {
	return calculateWeeksBetweenDates(startDate, endDate) > 12;
};

export const getWeeklyHoursDisplayMessages = (
	isInEditMode: boolean,
	weeklyHours: NullableNumber,
	period: { startDate?: string; endDate?: string },
	weeklyHoursDependantFieldsChanged: boolean
): NullableString => {
	const messages: string[] = [];

	if (isInEditMode && weeklyHoursDependantFieldsChanged) {
		messages.push(i18n.t("commands.warning.weeklyHours.periodEdited"));
	}

	const { startDate, endDate } = period;

	if (startDate && endDate) {
		if (isPeriodLessThan12Weeks(startDate, endDate) && weeklyHours > 48) {
			messages.push(i18n.t("commands.warning.weeklyHours.aboveThreshold48"));
		}
		if (isPeriodMoreThan12Weeks(startDate, endDate) && weeklyHours > 44) {
			messages.push(i18n.t("commands.warning.weeklyHours.aboveThreshold44"));
		}
	}

	return isEmpty(messages) ? null : messages.join("\n");
};
