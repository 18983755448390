import i18n from "src/i18n";
import * as Yup from "yup";

export const isValidRange = (value: NullableString): boolean => {
	if (value === null || value === "" || value === undefined) return true;
	const numericValue = parseFloat(value.replace(",", "."));
	return numericValue >= 0 && numericValue <= 168;
};

export const isValidDecimal = (value: NullableString): boolean => {
	if (value === null || value === "" || value === undefined) return true;
	return !value.includes(".");
};

export const transformValue = (value: NullableString): NullableString => {
	return value ? value.replace(".", ",") : value;
};

const WeeklyHoursRule = () => {
	return Yup.string()
		.nullable()
		.matches(/^(\d{1,3})([.,]\d{1,2})?$/, i18n.t("commands.error.weeklyHoursInterval"))
		.test("is-valid-range", i18n.t("commands.error.weeklyHoursRange"), isValidRange)
		.transform(transformValue)
		.test("is-valid-decimal", i18n.t("commands.error.weeklyHoursDecimal"), isValidDecimal);
};

export default WeeklyHoursRule;
