import { FC } from "react";

import { TPlanningRow } from "src/types";

import { Checkbox } from "@zolteam/react-ras-library";

interface ICandidatPlanningRowProps {
	onChange: (planning: TPlanningRow) => void;
	values: TPlanningRow;
	label: string;
	name: string;
}

export const CandidatPlanningRow: FC<ICandidatPlanningRowProps> = ({ values, onChange, label, name }) => {
	const handleChange = (value: boolean, key: string) => {
		onChange({
			...values,
			[key]: value,
		});
	};

	const isAllChecked = values.morning && values.afternoon && values.night;

	return (
		<tr>
			<td>
				<Checkbox
					theme="primary"
					value={isAllChecked}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						const value = event.target.checked;
						onChange({
							morning: value,
							afternoon: value,
							night: value,
						});
					}}
					label={label}
					className="[&>label]:text-sm [&>label]:!text-black [&>label]:capitalize"
					id={name + ".all"}
				/>
			</td>
			<td>
				<Checkbox
					theme="primary"
					className="w-fit"
					value={values.morning}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleChange(event.target.checked, "morning");
					}}
					id={name + ".morning"}
				/>
			</td>
			<td>
				<Checkbox
					theme="primary"
					className="w-fit"
					value={values.afternoon}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(event.target.checked, "afternoon")
					}
					id={name + ".afternoon"}
				/>
			</td>
			<td>
				<Checkbox
					theme="primary"
					className="w-fit"
					value={values.night}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(event.target.checked, "night")
					}
					id={name + ".night"}
				/>
			</td>
		</tr>
	);
};
