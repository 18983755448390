// @ts-nocheck

/* eslint-disable no-unused-vars */

/* eslint-disable max-len */
import { useEffect, useRef, useMemo, useState } from "react";

import Cookies from "js-cookie";
// Hooks
import { useParams } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import io from "socket.io-client";

// Utils
import { WEBSOCKET_URL, V1_TOKEN_COOKIE } from "../constants";

import { SOCKET_EVENTS } from "src/constants/socketEvent";

const useEnabledFeatures = () => {
	const { orderId } = useParams();
	const socketIo = useRef(null);
	const [userList, setUserList] = useState([]);

	const authToken = useMemo(() => {
		const authCookie = Cookies.get(V1_TOKEN_COOKIE);
		return JSON.parse(authCookie)?.access_token;
	}, []);

	const params = {
		reconnect: true,
		reconnectionDelay: 10000,
		maxReconnectionAttempts: 10,
		secure: true,
		query: { authToken },
	};

	useEffect(() => {
		socketIo.current = io.connect(`${WEBSOCKET_URL}/client-v2`, params);

		// Handlers
		const handleUserConnect = () => {
			socketIo.current.emit(SOCKET_EVENTS.ORDER_PROFILES_USER_JOINS_ROOM, {
				orderId,
				authToken,
			});
		};

		const handleOrderProfilesUserListUpdated = (updatedList) => {
			setUserList(updatedList);
		};

		const handleOrderProfileStatusUpdate = (event) => {
			// TODO add the logic to move card on order profile mangement KANBAN
			// eslint-disable-next-line no-console
			console.log(event);
		};

		socketIo.current.on("connect", handleUserConnect);
		socketIo.current.on(SOCKET_EVENTS.ORDER_PROFILES_USER_LIST_UPDATED, handleOrderProfilesUserListUpdated);
		socketIo.current.on(SOCKET_EVENTS.ORDER_PROFILE_STATUS_UPDATE, handleOrderProfileStatusUpdate);

		return () => {
			socketIo.current.off("connect", handleUserConnect);
			socketIo.current.off(SOCKET_EVENTS.ORDER_PROFILES_USER_LIST_UPDATED, handleOrderProfilesUserListUpdated);
			socketIo.current.off(SOCKET_EVENTS.ORDER_PROFILE_STATUS_UPDATE, handleOrderProfileStatusUpdate);
			socketIo.current.disconnect();
		};
	}, [orderId, authToken]);

	return {
		userList,
	};
};

export default useEnabledFeatures;
