import { FormikErrors } from "formik";
import { get, isEmpty, isNil } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";

export const getWorkingTimeErrorMessage = (errors: FormikErrors<OrderInterface>): string | null => {
	if (isNil(errors.workingTime) || isEmpty(errors.workingTime?.slots)) {
		return null;
	}

	const { workingTime } = errors;

	let startHourError = null;
	let endHourError = null;

	for (let i: number = 0; i < workingTime.slots.length; i++) {
		startHourError = get(workingTime.slots[i], "startHour", null);
		endHourError = get(workingTime.slots[i], "endHour", null);

		if (!isNil(startHourError) || !isNil(endHourError)) {
			break;
		}
	}

	return startHourError ?? endHourError;
};

export const getWeeklyHoursErrorMessage = (errors: FormikErrors<OrderInterface>): NullableString => {
	if (isNil(errors.workingTime) || isEmpty(errors.workingTime?.weeklyHours)) {
		return null;
	}

	return errors.workingTime.weeklyHours;
};

export const getSlotsRowErrorMessages = (errors: FormikErrors<OrderInterface>): NullableString => {
	const workingTimeError = getWorkingTimeErrorMessage(errors);
	const weeklyHoursError = getWeeklyHoursErrorMessage(errors);

	const errorMessages: string[] = [];

	if (!isNil(workingTimeError)) {
		errorMessages.push(workingTimeError);
	}

	if (!isNil(weeklyHoursError)) {
		errorMessages.push(weeklyHoursError);
	}

	return errorMessages.length > 0 ? errorMessages.join("\n") : null;
};
