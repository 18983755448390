import React, { createContext, useEffect, useState } from "react";

import { TFilters } from "src/types";

import { DEFAULT_FILTERS } from "src/constants/CFilters";

export type FiltersContextType<T> = {
	Filters: TFilters<T>;
	setFilters: React.Dispatch<React.SetStateAction<TFilters<T>>>;
};

interface IFiltersProviderProps extends React.PropsWithChildren<{}> {}

const FILTERS_STORAGE_KEY = "FiltersContext";

export const FiltersContext = createContext<FiltersContextType<unknown> | undefined>(undefined);

export const FiltersProvider: React.FC<IFiltersProviderProps> = ({ children }) => {
	const [Filters, setFilters] = useState<TFilters<unknown>>(() => {
		const savedFilters = sessionStorage.getItem(FILTERS_STORAGE_KEY);
		const filters = savedFilters ? JSON.parse(savedFilters) : DEFAULT_FILTERS;

		return {
			...DEFAULT_FILTERS,
			...filters,
		};
	});

	useEffect(() => {
		sessionStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(Filters));
	}, [Filters]);

	return <FiltersContext.Provider value={{ Filters, setFilters }}>{children}</FiltersContext.Provider>;
};
