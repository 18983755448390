import { FC, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import i18n from "src/i18n";
import { TApiError } from "src/types/TErrors";
import { TUserListing } from "src/types/TUsersListing";

import usersListingService from "src/services/usersListingService";

import ErrorOnAction, { TTemporaryWorkerOnError } from "./ErrorOnAction";
import { InfoMessage, Text } from "@zolteam/react-ras-library";
import { ConfirmModal } from "src/components/molecules";
import { CheckboxListFilter } from "src/components/organisms";

interface IActionUpdateVipProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	selectedItem: TUserListing[];
}

const ActionUpdateVip: FC<IActionUpdateVipProps> = ({ isOpen, setIsOpen, selectedItem }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [addVip, setAddVip] = useState("add");
	const [usersOnError, setUsersOnError] = useState<TTemporaryWorkerOnError[]>([]);

	const mutationActionUpdateVip = useMutation(
		({ usersIds, isVip }: { usersIds: number[]; isVip: boolean }) =>
			usersListingService.updateVipStatus({ usersIds, isVip }),
		{
			onSuccess: (data) => {
				if (data?.status === 207 && selectedItem?.length > 1) {
					setUsersOnError(data.data);
				} else {
					queryClient.invalidateQueries({ queryKey: ["postUsersListingSearch"] });
					toast.success(
						selectedItem?.length === 1
							? t(`usersListing.apiResponse.success.updatedSheet`)
							: t(`usersListing.apiResponse.success.updatedSheets`)
					);
				}
			},
			onError: (error: AxiosError<TApiError>) => {
				const message = error?.response?.data?.message;
				if (i18n.exists(`usersListing.apiResponse.errors.${message}`)) {
					toast.error(
						t(`usersListing.apiResponse.errors.${message}`, {
							name: selectedItem[0]?.name,
							count: selectedItem?.length === 1 ? 1 : 2,
						})
					);
				} else {
					toast.error(t(`global.apiErrors.undefined`));
				}
			},
			onSettled: (data) => {
				if (data?.status !== 207) {
					setIsOpen(false);
				}
			},
		}
	);

	const handleUpdateVip = (usersIds: number[], isVip: boolean) => {
		return mutationActionUpdateVip.mutateAsync({ usersIds, isVip });
	};

	return (
		<ConfirmModal
			title={t("usersListing.primaryActions.vip.title")}
			isOpen={isOpen}
			onClose={() => {
				setUsersOnError([]);
				setIsOpen(false);
			}}
			onConfirm={() => {
				return handleUpdateVip(
					selectedItem.map((sI) => sI.id),
					selectedItem?.length === 1 ? !selectedItem[0].isVip : addVip === "add"
				);
			}}
			closeOnConfirm={false}
			confirmText={t("global.register")}
			size="s"
			className="!w-[26rem]"
			buttons={usersOnError?.length > 0 ? ["", ""] : null}
		>
			<div className="flex flex-col gap-8">
				{usersOnError?.length > 0 ? (
					<ErrorOnAction usersOnError={usersOnError} allUser={selectedItem} />
				) : (
					<>
						<div className="w-full [&_span]:overflow-auto [&_span]:max-h-20 [&_span]:items-start">
							<InfoMessage color={"warning"} withIcon>
								{selectedItem?.length === 1
									? t("usersListing.primaryActions.vip.warnText")
									: t("usersListing.primaryActions.vip.warnTextMulti", {
											workers: selectedItem?.map((item) => `${item.name}`).join(", "),
										})}
							</InfoMessage>
						</div>
						<Text tag="span" size="paragraph01">
							{selectedItem?.length === 1 ? (
								t("usersListing.primaryActions.vip.text")
							) : (
								<div className="flex flex-col gap-8">
									<CheckboxListFilter
										items={{ add: true, remove: false }}
										type="radio"
										onChange={(item) => {
											setAddVip(item);
										}}
										getLabel={(item) => {
											return t(`usersListing.primaryActions.vip.${item}`);
										}}
										getValue={(type) => {
											return type === addVip;
										}}
										listStyle={{ gap: "0.75rem" }}
										labelClassName="gap-3 py-3 pl-2 pr-0 rounded-lg w-full cursor-pointer"
										selectedLabelClassName="bg-primary-50 text-primary-500"
									/>
								</div>
							)}
						</Text>
					</>
				)}
			</div>
		</ConfirmModal>
	);
};

export default ActionUpdateVip;
