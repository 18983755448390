// @ts-nocheck
import { TDocumentsTypesSearch } from "src/types/TDocuments";
import { TDocumentsTypes } from "src/types/TIntegration";

import client from "./client";

/**
 * @description Get all suspicious merges
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSuspiciousSearch = () => client.get("/suspicious");

/**
 * @description Search from all orders with adjustable params
 * @param {Object} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postOrdersSearch = (data) => client.post("/api-client-v2/orders/search", data);

/**
 * @description Get details of an order by its ID
 * @param {String} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrderUserFilters = () => client.get(`/api-client-v2/orders/user-filters`);

/**
 * @description Get agencies for logged user
 * @param {String} search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAgencies = (search) =>
	client.get("/api-client-v2/users/agencies", {
		params: {
			search: `${search}`,
		},
	});

/**
 * @description Get list of all agencies clients
 * @param {String} agencyId
 * @param {String} search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getClients = (agencyId, search) =>
	client.get(`/api-client-v2/agencies/${agencyId}/clients`, {
		params: {
			search: `${search}`,
		},
	});

/**
 * @description Get list of all commercial codes from an agency
 * @param {String} agencyId
 * @param {String} search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommercialCodes = (agencyId, search) =>
	client.get(`/api-client-v2/agencies/${agencyId}/commercial-codes`, {
		params: {
			search: `${search}`,
		},
	});

/**
 * @description Get list of all client's contacts
 * @param {String} agencyId
 * @param {String} clientId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getInterlocutors = (agencyId, clientId) =>
	client.get(`/api-client-v2/orders/agencies/${agencyId}/clients/${clientId}/contacts`);

/**
 * @description Get list of all agency's appeal cases
 * @param {String} agencyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAppealCases = (agencyId) => client.get(`/api-client-v2/agencies/${agencyId}/appeal-case-categories`);

/**
 * @description Get list of all qualifications from an Agency's client
 * @param {String} agencyId
 * @param {String} clientId
 * @param {String} search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getClientQualifications = (agencyId, clientId, search) =>
	client.get(`/api-client-v2/agencies/${agencyId}/clients/${clientId}/qualifications`, {
		params: {
			search,
		},
	});

/**
 * @description Get level list from qualification
 * @param {String} source
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getQualificationsLevels = (source) =>
	client.get("/api-client-v2/qualifications/level", {
		params: {
			source,
		},
	});

/**
 * @description Get contract types list
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractTypes = () => client.get("/api-client-v2/contract/types");

/**
 * @description Get list of recruitment model reasons
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRecruitmentModelReasons = () => client.get("/api-client-v2/recruitment-model-reasons");

/**
 * @description Get the client's work addresses
 * @param {String} agencyId
 * @param {String} clientId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getClientWorkAddresses = (agencyId, clientId) =>
	client.get(`/api-client-v2/agencies/${agencyId}/clients/${clientId}/work-addresses`);

/**
 * @description Get country list
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCountries = () => client.get("/api-client-v2/countries/list");

/**
 * @description Share offer to temp worker(s)
 * @param {String} orderId
 * @param {Array} orderProfileIds
 * @param {String} additionalInformations
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postOrderMission = (orderId, orderProfileIds, additionalInformations) =>
	client.post(`api-client-v2/orders/${orderId}/mission-order-share`, {
		orderProfileIds,
		additionalInformations,
	});

/**
 * @description Find available profiles for one order
 * @param {String} orderId
 * @param {String} search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAvailableProfiles = (orderId, { search, limit }) =>
	client.get(`api-client-v2/orders/${orderId}/profiles-available?search=${search}`, {
		params: {
			limit,
		},
	});

/**
 * @description Add profile(s) to an order
 * @param {String} orderId
 * @param {Array} unifiedTempWorkerIds
 * @param {String} status
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postAddProfilesToOrder = (orderId, unifiedTempWorkerIds, status) =>
	client.post(`api-client-v2/orders/${orderId}/profile`, {
		unifiedTempWorkerIds,
		status: `${status}`,
	});

/**
 * @description Update a profile status
 * @param {String} orderId
 * @param {String} orderProfileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putStatusProfileUpdate = (orderId, { orderProfileId, status, notifyUser, version }) =>
	client.put(`api-client-v2/orders/${orderId}/profile/status`, {
		orderProfileId,
		status,
		notifyUser,
		version,
	});

/**
 * @description Share mission order to temp worker(s)
 * @param {String} orderId
 * @param {Array} orderProfileIds
 * @param {Boolean} showClientName
 * @param {Boolean} showCityAndPostalCode
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postOrderShare = (orderId, orderProfileIds, showClientName, showCityAndPostalCode) =>
	client.post(`api-client-v2/orders/${orderId}/offer-share`, {
		orderProfileIds,
		showClientName,
		showCityAndPostalCode,
	});

/**
 * @description Get list of reasons for a not provided order
 * @returns {Promise<AxiosResponse<any>>}
 * @param {String} orderId
 */
export const getOrderNotProvidedReasons = (orderId) =>
	client.get(`api-client-v2/orders/${orderId}/order-not-provided-reasons`);

/**
 * @description Get the qualification for an order
 * @param {String} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrderQualification = (id) => client.get(`/api-client-v2/orders/${id}/qualification`);

/**
 * @description Get details information for an agency
 * @param {String} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAgencyDetails = (id) => client.get(`api-client-v2/agency/${id}`);

/**
 * @description Get all available features for an agency
 * @param {String} id - Agency ID
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAgencyAvailableFeatures = (id) => client.get(`api-client-v2/agencies/${id}/available-features`);

/**
 * @description Get details information for a client
 * @param {String} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getClientDetails = (id) => client.get(`api-client-v2/client/${id}`);

/**
 * @description Search profils that's match with an order  with adjustable params
 * @param {Object} data
 * @param {String} orderId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postMatchingOrderSearch = (orderId, data) =>
	client.post(`/api-client-v2/orders/${orderId}/matching/search`, data);

/**
 * @description Select profiles from matching and add it to an order
 * @param {String} orderId
 * @param {Array} unifiedTempWorkerId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postSelectProfile = (orderId, unifiedTempWorkerId) =>
	client.post(`/api-client-v2/orders/${orderId}/matching/select-profile`, {
		unifiedTempWorkerId,
	});

/**
 * @description Unselect profiles from matching and add it to an order
 * @param {String} orderId
 * @param {Array} unifiedTempWorkerId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postDeslectProfile = (orderId, unifiedTempWorkerId) =>
	client.post(`/api-client-v2/orders/${orderId}/matching/deselect-profile`, {
		unifiedTempWorkerId,
	});

/**
 * @description Get list of all vehicules
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVehiculesList = () => client.get("/api-client-v2/vehicles");

/**
 * @description Get list of all out of business reasons
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOutOfBusinessReasons = () =>
	client.get("/api-client-v2/orders/matching/filters/out-of-business-reasons");
/**
 * @description Get unified temp worker informations
 * @param {String} unifiedTempWorkerId
 * @param {String} agencyId
 * @param {String} orderId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMatchingTempWorkerInformations = (orderId, unifiedTempWorkerId, agencyId) =>
	client.get(`/api-client-v2/orders/${orderId}/matching/unified-temp-worker/${unifiedTempWorkerId}/informations`, {
		params: {
			agencyId,
		},
	});

/**
 * @description Get unified temp worker informations
 * @param {String} orderId
 * @param {String} unifiedTempWorkerId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMatchingTempWorkerOtherOrders = (orderId, unifiedTempWorkerId) =>
	client.get(`/api-client-v2/orders/${orderId}/matching/unified-temp-worker/${unifiedTempWorkerId}/other-orders`);

/**
 * @description Get common-documents list
 * @param {String} search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommonDocuments = (
	params: TDocumentsTypesSearch
): Promise<AxiosResponse<{ items: TDocumentsTypes[]; total: number }>> =>
	client.post("/api-client-v2/common-documents/search", params);

/**
 * @description Add multiples profiles to an order
 * @param {String} orderId
 * @param {Object} filters
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postAddMatchedProfiles = (orderId, filters) =>
	client.post(`/api-client-v2/orders/${orderId}/matching/search/add-matched-profiles`, filters);

/**
 * @description Get activity details for an order profile
 * @param {Object} data
 * @param {String} orderProfileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postProfileDetails = (orderProfileId, data) =>
	client.post(`/api-client-v2/order-profiles/${orderProfileId}/activities/search`, data);

/**
 * @description Get notes for an order profile
 * @param {String} orderProfileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrderProfileNotes = (orderProfileId) =>
	client.get(`/api-client-v2/order-profile-notes/${orderProfileId}`);

/**
 * @description Edit notes for an order profile
 * @param {String} orderProfileId
 * @param {Object} data { notes: 'string', notesVersion: int}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putOrderProfileNotes = (orderProfileId, data) =>
	client.put(`/api-client-v2/order-profile-notes/${orderProfileId}`, data);

/**
 * @description Get communication details for a profile
 * @param {String} orderMessagingGroupUuid
 * @param {String} orderProfileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommunicationContent = (orderProfileId, orderMessagingGroupUuid) =>
	client.get(`/api-client-v2/order-profiles/${orderProfileId}/order-messaging/${orderMessagingGroupUuid}`);

/**
 * @description Return a boolean truthy or not wether the order has been updated on tempo's side
 * @param {String} orderId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrderTempoStatus = (orderId) => client.get(`/api-client-v2/orders/${orderId}/tempo-status`);

/**
 * @description Get instructions list
 * @param {String} agencyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getInstructions = (agencyId) => client.get(`/api-client-v2/agencies/${agencyId}/instructions`);
