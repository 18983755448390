import { FC } from "react";

import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { Picto } from "@zolteam/react-ras-library";
import { Field } from "src/components/molecules";

import style from "./PhoneInput.module.css";

interface PhoneInputProps {
	name: string;
	label?: string;
	className?: string;
	classNameContainer?: string;
	required?: boolean;
}

const ssnMask: MaskitoOptions = {
	mask: [/\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/],
};

export const PhoneInput: FC<PhoneInputProps> = ({ name, label, className, classNameContainer, required }) => {
	const { t } = useTranslation();
	const { errors, values, setFieldValue, touched } = useFormikContext<any>();
	const inputRef = useMaskito({ options: ssnMask });
	return (
		<Field
			type="phone"
			name={name}
			innerRef={inputRef}
			id={name}
			label={label || t("global.phone")}
			classNameContainer={classNameContainer}
			className={className}
			maxLength={14}
			customErrorDisplay
			onInput={(e: any) => {
				setFieldValue(name, e.target.value || null);
			}}
			required={required}
		>
			{!errors[name] && values[name] && (
				<a href={`tel:${values[name]}`}>
					<Picto
						className={style.phonePicto + " " + (errors[name] && touched[name] && style.errorPicto)}
						icon={"phoneOutgoing"}
						onClick={() => {
							return "";
						}}
					/>
				</a>
			)}
		</Field>
	);
};
