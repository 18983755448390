// Style
import { TDate } from "src/types/TDates";

import cn from "../../../utils/cn";
import { Picto, Text, Tooltip } from "@zolteam/react-ras-library";

import styles from "./DateTimeRangeDisplay.module.css";

interface IDateTimeRangeDisplayProps {
	date: NullableString;
	onClick?: () => void;
	hours: TDate[];
	locked?: boolean;
	shouldDisplayWarning?: boolean;
}

export const DateTimeRangeDisplay: React.FC<IDateTimeRangeDisplayProps> = ({
	date,
	onClick = () => {},
	hours = [],
	locked = true,
	shouldDisplayWarning = false,
}) => {
	const hasStartHour0 = hours[0]?.startHour;
	const hasEndHour0 = hours[0]?.endHour;
	const hasStartHour1 = hours[1]?.startHour;
	const hasEndHour1 = hours[1]?.endHour;

	const hasSingleStartTime = hasStartHour0 && hours?.length === 1 && !hasEndHour0;
	const isStartHourWithoutEndHour = hasStartHour0 && !hasEndHour0 && !hasStartHour1 && hasEndHour1;
	const hasBothStartAndEndTimes = hasStartHour0 && hasEndHour0 && hasStartHour1 && hasEndHour1;
	const hasStartTimeAndEndTime = hasStartHour0 && hasEndHour0;

	const calculateTimeRange = () => {
		if (hasSingleStartTime) {
			return hours[0].startHour;
		}
		if (isStartHourWithoutEndHour) {
			return `${hours[0]?.startHour} - ${hours[1].endHour}`;
		}
		if (hasBothStartAndEndTimes) {
			return `${hours[0]?.startHour} - ${hours[0].endHour} 
    ${hours[1]?.startHour} - ${hours[1].endHour}`;
		}
		if (hasStartTimeAndEndTime) {
			return `${hours[0]?.startHour} - ${hours[0].endHour}`;
		}
		return null;
	};
	return calculateTimeRange() ? (
		<Tooltip
			animation={false}
			arrow={false}
			content={
				<div className={styles.hours}>
					<div className={styles.hour}>{calculateTimeRange()}</div>
				</div>
			}
		>
			<div
				className={cn([
					styles.container,
					styles.popoverHover,
					shouldDisplayWarning ? styles.warningBackground : null,
				])}
			>
				<Text tag="span" size="paragraph02">
					{date}
				</Text>
				{date ? (
					<button type="button" onClick={onClick} className={cn([styles.buttonIcon, "table-icon"])}>
						{locked ? <Picto icon="lock" style={{ width: "12px" }} /> : null}
					</button>
				) : null}
			</div>
		</Tooltip>
	) : (
		<div className={cn([styles.container, shouldDisplayWarning ? styles.warningBackground : null])}>
			<Text tag="span" size="paragraph02">
				{date}
			</Text>
			{date ? (
				<button type="button" onClick={onClick} className={cn([styles.buttonIcon, "table-icon"])}>
					{locked ? <Picto icon="lock" style={{ width: "12px" }} /> : null}
				</button>
			) : null}
		</div>
	);
};
