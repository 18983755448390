import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTitle } from "react-use";

import { format, getUnixTime } from "date-fns";
import { fr as localeFr } from "date-fns/locale";

// Services
import { getUnifiedTempWorker } from "../../../../services/missionFollowUpService";
import { searchClientContracts } from "../../../../services/unifiedTempWorkerService";

// Hooks
import useInterval from "../../../../hooks/useInterval";

// Constants
import { PAGINATION_OFFSET_OPTIONS, TOOLTIP_THRESHOLD } from "../../../../constants";
// Utils
import { dateDifference, formatDate } from "../../../../utils/formatDate";
import { definePageOffset } from "../../../../utils/paginationUtils";
import { TContractListResponse } from "./types/TContractsList";
import { PaginationV2, Picto, SelectPopover, Text, Tooltip } from "@zolteam/react-ras-library";
import { Tag } from "src/components/atoms";
import { ColumnListing, DateTimeRangeDisplay, PaginationSearch } from "src/components/molecules";
import { TableListing } from "src/components/organisms";

import style from "./ProfileMissionsContracts.module.css";

const DEFAULT_SEARCH_PARAMS = {
	page: 1,
	limit: 40,
};

const ProfileMissionsContracts = () => {
	const { t } = useTranslation();
	const { profileId } = useParams<ProfileMissionsOrdersParams>();
	useTitle(t("profile.contract.title"));

	const [data, setData] = useState<TContractListResponse>();
	const [search, setSearch] = useState(DEFAULT_SEARCH_PARAMS);
	const [isFirstLoading, setIsFirstLoading] = useState(true);

	const setUpdatedData = (res: TContractListResponse) => {
		const updatedData = res?.items?.reduce((acc, item) => {
			let updateDiff = null;
			if (item.updatedAt === item.createdAt) {
				updateDiff = null;
			} else {
				updateDiff = dateDifference(
					getUnixTime(new Date(item.updatedAt * 1000)),
					getUnixTime(Date.now()) * 1000
				);
			}
			acc.push({ ...item, updateDiff });
			return acc;
		}, []);
		setData({ ...res, items: updatedData });
	};

	const { data: unifiedTempWorker } = useQuery(["unified-temp-worker", profileId], () =>
		getUnifiedTempWorker(profileId)
	);

	const searchClientContractsMutation = useMutation<
		AxiosResponse<TContractListResponse>,
		Error,
		{ page: number; limit: number }
	>((params) => searchClientContracts(profileId, params), {
		onSuccess: (result) => {
			setUpdatedData(result?.data);
			setIsFirstLoading(false);
		},
	});

	const { isError: issearchClientContractsError, isLoading: issearchClientContractsLoading } =
		searchClientContractsMutation;

	useInterval(() => {
		setUpdatedData(data);
	}, 60000);

	const handleSearch = (sort: { page: number; limit: number }) => {
		setSearch((prev) => {
			const searchRequest = { ...prev, ...sort };
			return searchRequest;
		});
		searchClientContractsMutation.mutate({ ...search, ...sort });
	};

	const isListLoading = () => (isFirstLoading && !issearchClientContractsError) || issearchClientContractsLoading;

	useEffect(() => {
		searchClientContractsMutation.mutate(search);
	}, []);

	return (
		<div className={style.clientRelationships}>
			<div className={style.clientRelationshipList}>
				<TableListing
					loading={isListLoading()}
					fullHeight
					canBeChecked={false}
					error={issearchClientContractsError}
					data={data?.items}
					handleSearch={() => {}}
					onDoubleClick={() => null}
					noDataText={t("profile.contract.listing.noResultMessage", {
						firstName: unifiedTempWorker?.data?.firstName || "",
						lastName: unifiedTempWorker?.data?.lastName || "",
					})}
					noBorder
					initialColumnSort={{
						field: "updatedAt",
						orderBy: "DESC",
					}}
					footer={
						data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handleSearch}
											max={Math.round(data.total / search.limit)}
										/>
									}
									currentPage={search.page}
									totalCount={data.total}
									pageSize={search.limit}
									siblingCount={2}
									onPageChange={(activePage: number) =>
										handleSearch({ page: activePage, limit: search.limit })
									}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: search.limit * search.page - search.limit + 1,
										resultEnd:
											search.limit * search.page <= data?.total
												? search.limit * search.page
												: data?.total,
										resultTotal: data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value: number) =>
										handleSearch({
											limit: value,
											page: definePageOffset(value, search.limit, search.page),
										})
									}
									value={
										search.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === search.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{/* CONTRACT NUMBER */}
					<ColumnListing
						id="contractNumber"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.contractNumber")}
							</Text>
						}
						field={(elem) => ({ contractNumber: elem.contractNumber })}
						component={({ data: { contractNumber } }) => (
							<div className={style.column}>{contractNumber}</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* CLIENT */}
					<ColumnListing
						id="clientName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.clientName")}
							</Text>
						}
						field={(elem) => ({ clientName: elem.clientName })}
						component={({ data: { clientName } }) => (
							<div className={style.column}>
								{clientName?.length > TOOLTIP_THRESHOLD ? (
									<Tooltip animation={false} arrow={false} content={clientName || ""}>
										<div>{`${clientName?.substring(0, TOOLTIP_THRESHOLD)}...`}</div>
									</Tooltip>
								) : (
									clientName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* QUALIFICATION */}
					<ColumnListing
						id="qualificationName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.qualificationName")}
							</Text>
						}
						field={(elem) => ({ qualificationName: elem.qualificationName })}
						component={({ data: { qualificationName } }) => (
							<div className={style.column}>
								{qualificationName?.length > TOOLTIP_THRESHOLD ? (
									<Tooltip animation={false} arrow={false} content={qualificationName || ""}>
										<div>{`${qualificationName?.substring(0, TOOLTIP_THRESHOLD)}...`}</div>
									</Tooltip>
								) : (
									qualificationName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* AGENCY */}
					<ColumnListing
						id="agency"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.agencyName")}
							</Text>
						}
						field={(elem) => ({
							agencyName: elem.agencyName.replace("RAS ", ""),
						})}
						component={({ data: { agencyName } }) => (
							<div className={style.column}>
								{agencyName?.length > TOOLTIP_THRESHOLD ? (
									<Tooltip animation={false} arrow={false} content={agencyName || ""}>
										<div>{`${agencyName?.substring(0, TOOLTIP_THRESHOLD)}...`}</div>
									</Tooltip>
								) : (
									agencyName
								)}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* CONTRACT START DATE */}
					<ColumnListing
						id="contractStartDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.startDate")}
							</Text>
						}
						field={(elem) => ({
							contractStartDate: elem.contractStartDate,
							startTimeAm: elem.startTimeAm,
							finishTimeAm: elem.finishTimeAm,
							startTimePm: elem.startTimePm,
							finishTimePm: elem.finishTimePm,
						})}
						component={({
							data: { contractStartDate, startTimeAm, finishTimeAm, startTimePm, finishTimePm },
						}) => (
							<DateTimeRangeDisplay
								hours={[
									{ startHour: startTimeAm, endHour: finishTimeAm },
									{ startHour: startTimePm, endHour: finishTimePm },
								]}
								date={formatDate(new Date(contractStartDate), "dd MMM yyyy")}
								locked={false}
								onClick={() => {}}
							/>
						)}
						tdClassName="min-column-width"
					/>

					{/* CONTRACT END DATE */}
					<ColumnListing
						id="contractEndDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.endDate")}
							</Text>
						}
						field={(elem) => ({ contractEndDate: elem.contractEndDate })}
						component={({ data: { contractEndDate } }) => (
							<div className={style.column}>
								{format(new Date(contractEndDate), "dd MMM yyyy", {
									locale: localeFr,
								})}
							</div>
						)}
						tdClassName="min-column-width"
					/>

					{/* FLEXIBILITY */}
					<ColumnListing
						id="flexibility"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.hasFlexibility")}
							</Text>
						}
						field={(elem) => ({
							hasFlexibility: elem.hasFlexibility,
						})}
						component={({ data: { hasFlexibility } }) => (
							<Tag color={hasFlexibility ? "primary" : "grey"}>
								{t(
									`profile.contract.listing.${hasFlexibility ? "flexibilityActivated" : "flexibilityDesactivated"}`
								)}
							</Tag>
						)}
						tdClassName="min-column-width"
					/>

					{/* FLEXIBILITY END DATE */}
					<ColumnListing
						id="flexibilityEndDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("profile.contract.listing.flexibilityEndDate")}
							</Text>
						}
						field={(elem) => ({ flexibilityEndDate: elem.flexibilityEndDate })}
						component={({ data: { flexibilityEndDate } }) => (
							<div className={style.column}>
								{flexibilityEndDate
									? format(new Date(flexibilityEndDate), "dd MMM yyyy", { locale: localeFr })
									: ""}
							</div>
						)}
						tdClassName="min-column-width"
					/>
				</TableListing>
			</div>
		</div>
	);
};

export default ProfileMissionsContracts;
