import "moment/locale/fr";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";

// Misc
import { commandPeriodFormDefaultValues } from "../commandFormDefaultValues";
import { DATE_FORMAT_STRING, END_DATE_INPUT_NAME, START_DATE_INPUT_NAME } from "./constants";
// Hooks
import useCommandTimeFormBlock from "./useCommandTimeFormBlock";
// Utils
import { checkWorkDaysSelectedAreOutOfPeriod } from "./utils/checkWorkDaysSelectedAreOutOfPeriod";
import { getEndDateRangeInputErrorMessage } from "./utils/getEndDateRangeInputErrorMessage";
import { getSlotsRowErrorMessages } from "./utils/getSlotsRowErrorMessages";
import { getStartDateRangeInputErrorMessage } from "./utils/getStartDateRangeInputErrorMessage";
import { isPeriodError } from "./utils/isPeriodError";
import { isWorkingTimeOnError } from "./utils/isWorkingTimeOnError";
import { showWorkingTimeTooltipError } from "./utils/showWorkingTimeTooltipError";
import { DaysSelector, TabSwitch, Textarea, WorkHoursTimeSlots, InputControlled } from "@zolteam/react-ras-library";
// Components
import { DateRangeInput } from "src/components/atoms/DateRangeInput/DateRangeInput";
import { TooltipMessage } from "src/components/atoms/TooltipMessage/TooltipMessage";
import { DateRangePicker } from "src/components/molecules/DateRangePicker/DateRangePicker";

// Style
import cn from "src/utils/cn";

// Constants
import { COMMAND_TYPE_INTERIM } from "src/constants";

import style from "./CommandTimeFormBlock.module.css";

interface CommandTimeFormBlockProps {
	isOrderFetched: boolean;
}

const CommandTimeFormBlock = ({ isOrderFetched }: CommandTimeFormBlockProps) => {
	const {
		t,
		isBlockLoading,
		startDateDayIndex,
		tabs,
		currentTab,
		showFullSize,
		showOneColumn,
		focusedInputName,
		translatedDays,
		isNight,
		areAllDaysSelected,
		workingDaysToIndexesArray,
		values,
		touched,
		errors,
		weeklyHoursDisplayMessages,
		weeklyInputRef,
		setFocusedInputName,
		handleTabChange,
		handleClearPeriodDate,
		handleDateRangeUpdate,
		handleDayClick,
		handleChangeWorkHours,
		handleWeeklyHoursChange,
		getOnDeleteSlotsLine,
		setFieldValue,
		setFieldTouched,
		setIsWeeklyHoursFocused,
	} = useCommandTimeFormBlock({ isOrderFetched });

	const { type, period, workingTime } = values;

	if (isBlockLoading) {
		return (
			<div className={style.skeletonColumn}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonPeriod} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonPeriod} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonDaysSelected} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className={style.workPeriodType}>
				<TabSwitch
					minTabWidth="7.5rem"
					tabs={tabs.map((tab) => tab.label)}
					activeTab={currentTab}
					onTabChange={handleTabChange}
				/>
			</div>
			<div
				className={cn([
					style.dateRangeWrapper,
					showFullSize || showOneColumn ? style.horizontalDateRange : style.verticalDateRange,
				])}
			>
				<div className={style.dateRangeContent}>
					<DateRangeInput
						label={t("commands.periodForm.start")}
						value={period.startDate ? moment(period.startDate).format(DATE_FORMAT_STRING) : ""}
						onClick={async () => {
							setFieldTouched("period.startDate", true);
							setFocusedInputName(START_DATE_INPUT_NAME);
						}}
						onClear={(e) => handleClearPeriodDate(START_DATE_INPUT_NAME, e)}
						className={cn([style.fieldStartDate, style.dateRangeField])}
						isActive={focusedInputName === START_DATE_INPUT_NAME}
						error={getStartDateRangeInputErrorMessage(touched, errors)}
					/>
					<DateRangeInput
						label={t("commands.periodForm.end")}
						value={period.endDate ? moment(period.endDate).format(DATE_FORMAT_STRING) : ""}
						onClick={async () => {
							setFieldTouched("period.endDate", true);
							setFocusedInputName(END_DATE_INPUT_NAME);
						}}
						onClear={(e) => handleClearPeriodDate(END_DATE_INPUT_NAME, e)}
						className={cn([style.dateRangeField, style.fieldEndDate])}
						isActive={focusedInputName === END_DATE_INPUT_NAME}
						error={getEndDateRangeInputErrorMessage(touched, errors)}
					/>
					{isPeriodError(touched, errors) && (
						<TooltipMessage className={cn([style.centerTooltip, style.dateTooltip])} color="error">
							{errors.period.startDate || errors.period.endDate}
						</TooltipMessage>
					)}
					<DateRangePicker
						className={style.datePickerWrapper}
						// @ts-ignore
						dateFrom={moment(period.startDate || new Date())
							.startOf("day")
							.add(12, "hours")}
						// @ts-ignore
						dateTo={period.endDate ? moment(period.endDate) : null}
						focusedInput={focusedInputName.replace("period.", "")}
						setFocusedInput={(reactDatesInputName) =>
							setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
						}
						onDatesChange={handleDateRangeUpdate}
						keepOpenOnDateSelect
						isVisible={focusedInputName !== ""}
						isDayBlocked={() => false}
						setIsVisible={() => setFocusedInputName("")}
					/>
				</div>
				{/* Jours travaillés */}
				<div
					className={cn([
						style.daysSelectorGroup,
						showFullSize || showOneColumn ? null : style.verticalDaysSelector,
					])}
				>
					<DaysSelector
						className={cn([style.daysSelector])}
						displayDays={translatedDays}
						lockedDays={[startDateDayIndex]}
						lockedDaysMessage={t("commands.lockedDayMessage")}
						selectedIndexDays={workingDaysToIndexesArray || []}
						selectAllLabel={
							areAllDaysSelected ? t("commands.unSelectAllDaysAction") : t("commands.selectAllDaysAction")
						}
						onDayClick={handleDayClick}
						onSelectAllClick={null}
						isNightPeriod={isNight}
					/>
					{checkWorkDaysSelectedAreOutOfPeriod(values) === true && (
						<TooltipMessage className={cn([style.centerTooltip])} color="warning" arrow={false}>
							{t("commands.daysSelectedDontMatch")}
						</TooltipMessage>
					)}
				</div>
			</div>
			{/* Working hours */}
			<div
				className={cn([
					style.periodWrapper,
					showFullSize ? style.showFullSizePeriod : style.periodVerticalWrapper,
				])}
			>
				<WorkHoursTimeSlots
					singleRange={isNight}
					errors={isWorkingTimeOnError(touched, errors)}
					slotsConfig={{
						popoverLabels: {
							delete: t("commands.periodForm.delete"),
							add: t("commands.periodForm.add"),
							clear: t("commands.periodForm.clear"),
						},
						names: {
							start: "startHour",
							end: "endHour",
						},
						labels: {
							start: null,
							end: null,
						},
						menuStartTime: {
							start: isNight ? "16:00" : "05:00",
							end: isNight ? "05:00" : "16:00",
						},
						placeholders: {
							start: isNight ? "--:--*" : "--:--",
							end: isNight ? "--:--*" : "--:--",
						},
					}}
					showSeparators={showOneColumn || !showFullSize}
					direction={showFullSize || showOneColumn ? "horizontal" : "vertical"}
					onChange={(e: { name: string; value: object }) => handleChangeWorkHours(e)}
					onNewSlotsLine={(defaultSlotValues: { startHour: null; endHour: null }) =>
						setFieldValue("workingTime.slots", workingTime.slots.concat(defaultSlotValues))
					}
					onDeleteSlotsLine={getOnDeleteSlotsLine}
					values={
						workingTime.slots.length
							? workingTime.slots
							: commandPeriodFormDefaultValues?.workingTime?.slots
					}
					className={style.workHoursTime}
				/>
				{!showOneColumn && <div className={style.timeSeparator} />}
				<div className="flex gap-2.5">
					<div
						className={cn([
							style.weeklyHoursField,
							!values.workingTime?.weeklyHours ? style.isInputEmpty : null,
						])}
					>
						<InputControlled
							type="text"
							name="workingTime.weeklyHours"
							id="workingTime.weeklyHours"
							label={t("commands.periodForm.weeklyHours")}
							innerRef={weeklyInputRef}
							error={!!errors.workingTime?.weeklyHours}
							placeholder={t("commands.periodForm.weeklyHours")}
							value={values.workingTime?.weeklyHours?.toString().replace(".", ",") || null}
							onFocus={() => setIsWeeklyHoursFocused(true)}
							onInput={handleWeeklyHoursChange}
							onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
								const converted = parseFloat(e.target.value?.replace(",", ".")) || null;
								setFieldValue("workingTime.weeklyHours", converted);
								setIsWeeklyHoursFocused(false);
							}}
						/>
					</div>
					{!showWorkingTimeTooltipError(touched, errors) && weeklyHoursDisplayMessages && (
						<TooltipMessage
							className={style.centerTooltip}
							color="warning"
							tooltipBoxStyle={{ maxWidth: "800px" }}
							arrow={false}
						>
							{weeklyHoursDisplayMessages}
						</TooltipMessage>
					)}
					{showWorkingTimeTooltipError(touched, errors) && (
						<TooltipMessage
							className={cn([style.centerTooltip])}
							color="error"
							tooltipBoxStyle={{ maxWidth: "800px" }}
							arrow={false}
						>
							{getSlotsRowErrorMessages(errors)}
						</TooltipMessage>
					)}
				</div>
			</div>

			{/* Horaires particuliers - Période non travaillée */}
			{type === COMMAND_TYPE_INTERIM ? (
				<div className={style.comments}>
					<Textarea
						className={style.textarea}
						name="workingTime.particularWorkTime"
						label={t("commands.periodForm.particularWorkTimeTextAreaLabel")}
						maxLength={120}
						shouldDisplayErrorMessage={false}
					/>
					<Textarea
						className={style.textarea}
						name="workingTime.periodNotWorking"
						label={t("commands.periodForm.periodNotWorkingTextAreaLabel")}
						maxLength={40}
						shouldDisplayErrorMessage={false}
					/>
				</div>
			) : null}
		</div>
	);
};

export default CommandTimeFormBlock;
