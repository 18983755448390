// Types
import { OrderWorkingTimeSlotInterface } from "src/types/OrderWorkingTimeSlotInterface";

type TimePeriod = {
	startDate: string;
	endDate: string;
};

type WorkingDays = {
	[day: string]: boolean;
};

type WorkingNights = {
	[night: string]: boolean;
};

export const autoCompleteWeeklyHours = (
	period: TimePeriod,
	slots: OrderWorkingTimeSlotInterface[],
	workingDays?: WorkingDays,
	workingNights?: WorkingNights
): number => {
	const startDate = new Date(period.startDate);
	const endDate = new Date(period.endDate);

	const calculateHours = (start: string, end: string): number => {
		const [startHour, startMinute] = start.split(":").map(Number);
		const [endHour, endMinute] = end.split(":").map(Number);

		const startTotalMinutes = startHour * 60 + startMinute;
		const endTotalMinutes = endHour * 60 + endMinute;

		const totalMinutes =
			endTotalMinutes < startTotalMinutes
				? endTotalMinutes + 1440 - startTotalMinutes
				: endTotalMinutes - startTotalMinutes;

		return totalMinutes / 60;
	};

	const hoursPerSlot = slots.reduce((sum, slot) => sum + calculateHours(slot.startHour, slot.endHour), 0);

	const currentDate = new Date(startDate);
	let weeklyHours = 0;
	let maxWeeklyHours = 0;

	while (currentDate <= endDate) {
		const dayOfWeek = currentDate.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();

		const isWorkingDay = workingDays && workingDays[dayOfWeek] && currentDate >= startDate;
		const isWorkingNight =
			workingNights && workingNights[`${dayOfWeek}Night`] && currentDate >= startDate && currentDate <= endDate;

		if (isWorkingDay || isWorkingNight) {
			weeklyHours += hoursPerSlot;
		}

		currentDate.setDate(currentDate.getDate() + 1);

		if (currentDate.getDay() === 0 || currentDate > endDate) {
			if (weeklyHours > maxWeeklyHours) {
				maxWeeklyHours = weeklyHours;
			}
			weeklyHours = 0;
		}
	}

	return parseFloat(maxWeeklyHours.toFixed(2));
};
